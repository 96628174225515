import React from 'react';
import { Box, Flex,Input, Text, Alert, AlertIcon, Image } from "@chakra-ui/react";
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({ onDrop, isDragActive, uploaded, previewImage }) => {
  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/*,video/*'});

  return (
    <Flex direction="column" {...getRootProps()} borderWidth="1px" borderRadius="md" p="6" mt="4" mb="4" h="auto">
      <Input {...getInputProps()} />
      {
        isDragActive ?
          <Text>サムネ画像</Text> :
          <Text>ここにサムネ画像をドロップするか、クリックしてファイルを選択してください</Text>
      }
      {uploaded && <Alert status="success" mt="4">
        <AlertIcon />
        ファイルのアップロードに成功しました！
      </Alert>}
      {previewImage && (
        <Image
          src={previewImage}
          alt="Preview"
          boxSize="400px"
          objectFit="contain"
          m={0}
        />
      )}
    </Flex>
  );
};

export default FileDropzone;
