import { useState, useEffect } from 'react';
import { Box, VStack, Button, HStack, Heading, Text, useDisclosure, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    IconButton, Tooltip,Alert, AlertIcon,Input,Textarea
  } from '@chakra-ui/react';
import {MdHowToVote} from "react-icons/md"
import { useMetaMask } from '../services/MetaMaskContext';
import WaitTransaction from '../services/WaitTransaction';
import  {Storage } from 'aws-amplify';


function EvaluateButton({idea,onClosePrev}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [evaluateCount, setEvaluateCount] = useState(1);
    const [error, setError] = useState(null); // 追加
    const [transactionStatus, setTransactionStatus] = useState(null);//追加
    const [evaluationRemarks,setEvaluationRemarks] = useState('')
    
    const { contractWithSigner,setKey,setKeyState,username,account,ifIScored} = useMetaMask();
    

    //jobbers cannot evaluate実装


    const handleConfirm = async () => {
      try {
        setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット

        const evaluationObject = {
          account:account,
          score:evaluateCount,
          remarks:evaluationRemarks
        }
        const evaluationJsonStr = JSON.stringify(evaluationObject)

        const nameToSave = `ideas/${idea[2]}/evaluation/${account.toLowerCase()}.json`;

        await Storage.put(nameToSave, evaluationJsonStr);

        const tx = await contractWithSigner.evaluate(idea[0], evaluateCount);
        const receipt = await tx.wait();
        console.log('evaluated on blockchain successfully');

        setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット

      } catch(error) {
        console.error(error);
        // エラーメッセージから特定の部分だけを抽出して表示
        const errorMessage = error.reason || error.message;
        alert(errorMessage);
      
        // エラー情報をステートにセット
        setError(errorMessage); // エラーメッセージをstateに保存
        setTransactionStatus('error'); // トランザクションの状態を'error'にセット
      }
      

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1500); // 1500ミリ秒（1.5秒）待つ
      
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      onClosePrev()
      setKey(prevKey => prevKey + 1);
      setKeyState(true);

    }
    
    return (

      <>
            {transactionStatus && (
              <WaitTransaction transactionStatus={transactionStatus} />
            )} {/* 追加 */}

        <Tooltip label="評価・スコア" placement="right-start">
        <Button 
          colorScheme="teal" 
          onClick={onOpen} 
          borderRadius="full"
          _hover={{
            backgroundColor: "teal.400", // 2. ボタンの色が変わる
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
          }}
        >
          <MdHowToVote size="1.5em" /> 
        </Button>
        </Tooltip>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent >
            <ModalHeader
              marginLeft="80px"
            >
              Scoreを入力してください
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
              <NumberInput 
                value={evaluateCount} 
                onChange={(valueString) => setEvaluateCount(Number(valueString))} 
                min={1}
                max={10}
                maxW="100px"
                
                step={1}
                defaultValue={1}
                >
               <NumberInputField 
                textAlign="center"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Textarea placeholder='評価の概要を入力' size="md" height="200px"  mt='20px' value={evaluationRemarks} onChange={e=>setEvaluationRemarks(e.target.value)} />
            </VStack>
            </ModalBody>
            <ModalFooter justifyContent="center">
              <Button 
                colorScheme="teal" 
                onClick={handleConfirm}
                >確認</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }

  export default EvaluateButton;
  