import { useState, useEffect } from 'react';
import { Box, Button, Select, Text, Alert, AlertIcon, Image, Input, FormControl, FormLabel, Flex, VStack, Heading, HStack,
  Divider,Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Code }  from "@chakra-ui/react";
import  {Amplify, Storage,DataStore } from 'aws-amplify';
import {ethers} from "ethers";
import { FaCheckDouble } from 'react-icons/fa';
import { ProfitLossMap,BusinessModelMap } from '../models';
import { useMetaMask } from '../services/MetaMaskContext';


const ReviewIdea = ({isLoaded, idea,thisUsername }) => {

    const [jsonData, setJsonData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [remarks, setRemarks] = useState('')
    const [s3Hash, setS3Hash] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [profitLossEntry,setProfitLossEntry] = useState({})
    const [businessModelEntry,setBusinessModelEntry] = useState({})
    const {filterModelsByIdeaURI,allProfitLossMap,allBusinessModelMap} = useMetaMask();


    function extractText(str) {
        const match = str.match(/_(.*?)_/);
        if (match) {
            return match[1];
        }
        return null;
    }

    
    const handleLoad = async () => {
        try {
          const jsonFileName = idea[2]
          console.log('jsonFileName',jsonFileName)
          const pli=filterModelsByIdeaURI(allProfitLossMap,jsonFileName)
          setProfitLossEntry(pli)
          const bmi=filterModelsByIdeaURI(allBusinessModelMap,jsonFileName)
          setBusinessModelEntry(bmi)
  
          const etherFileName = extractText(jsonFileName)
          setFileName(etherFileName)
          const filesInDirectory = await Storage.list(`ideas/${jsonFileName}`);
          for (const file of filesInDirectory.results) {
            const fileUrl = await Storage.get(file.key);  // Get file URL
            if (file.key == `ideas/${jsonFileName}/${jsonFileName}.json`) {
              // fetch the JSON file and save it to state
              fetch(fileUrl)
                .then(response => response.json())
                .then(data => {
                    setJsonData(data);  // Update jsonData state
                    const _remarks = JSON.parse(data.combinedContent).remarks
                    setRemarks(_remarks)
                    const etherHash = ethers.utils.hexlify(data.hashTags)
                    setS3Hash(etherHash);
                });
            } else if (file.key.endsWith('.jpg') || file.key.endsWith('.png')|| file.key.endsWith('.jpeg')) {
              // Save the image URL to state
              setImageUrl(fileUrl);
            } 
          }
        } catch (error) {
          console.error('Error fetching files', error);
        }
    };

  
    const ShowModel = ({ modelTitle, ModelEntries }) => {
      // 表示しないプロパティのリスト
      const excludeFields = ['createdAt', 'updatedAt', '_version', '_lastChangedAt', '_deleted', 'id','ideaURI'];
    
      // モデルエントリのプロパティをレンダリングするヘルパー関数
      const renderModelProperties = (modelEntry) => {
        return Object.keys(modelEntry)
          .filter(key => !excludeFields.includes(key))
          .map((key) => {
            const title = key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').trim();
            return (
              <Box key={key} p={2}>
                <Text fontWeight="bold">{title}:</Text>
                <Text>{modelEntry[key]}</Text>
              </Box>
            );
          });
      };
    
      return (
        <VStack align="stretch" spacing={4} m={4}>
          <Heading as="h2" size="lg">{modelTitle}</Heading>

            <Box  borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
              {renderModelProperties(ModelEntries)}
            </Box>

        </VStack>
      );
    };

    const BusinessModelAccordion = ({ businessModelEntries }) => {
      const renderAccordionItem = (key, value) => {
        return (
          <AccordionItem key={key}>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  {key}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <VStack>
                {Object.entries(value).map(([subKey, subValue]) => (
                  <FormControl key={subKey}>
                    {/* <FormLabel>{subKey}</FormLabel> */}
                    {/* <Input value={subValue} readOnly /> */}
                    <Text>{subKey}.{subValue}</Text>
                  </FormControl>
                ))}
              </VStack>
            </AccordionPanel>
          </AccordionItem>
        );
      };
    
      return (
        <VStack align="stretch" spacing={4} m={4}>
        <Heading as="h2" size="lg">Business Model Map</Heading>
        <Accordion allowMultiple>
          {
            Object.entries(businessModelEntries).map(([key, value]) => {
              // JSON 文字列をパースして JavaScript オブジェクトに変換
              let parsedValue;
              try {
                parsedValue = JSON.parse(value);
              } catch (e) {
                parsedValue = value;
              }
              
              // パースした値がオブジェクトの場合はアコーディオンアイテムをレンダリング
              if (typeof parsedValue === 'object' && parsedValue !== null) {
                return renderAccordionItem(key, parsedValue);
              } else {
                // オブジェクトでない場合は何も表示しない（または別のレンダリングを実装）
                return null;
              }
            })
          }
        </Accordion>
        </VStack>
      );
    };

    
    function hashCompare() {
        let bytes32Hash = ethers.utils.hexlify(idea[5]);
        if (bytes32Hash === s3Hash) {
            return (<HStack><FaCheckDouble size={30}/><Text>No Alteration Detected on Blockchain</Text><FaCheckDouble size={30}/></HStack>)
        } else {
            return 'データに変更があります';
        }
    }

    useEffect(() => {
      if (isLoaded) {
        handleLoad().then(hashCompare);
        console.log('loaded!',)
      }
    }, [isLoaded]);

    const Remarks = ({ remarks }) => {
      // 数字の前に改行を挿入
      const insertInitialBreaks = (text) => {
        let count = 0;
        let result = '';
        for (const part of text.split(/(\b[1-3]\b)/)) {
          if (part === '1' || part === '2' || part === '3') {
            if (count < 3) { // 最初の3つの1, 2, 3のみに対して改行を挿入
              result += '\n' + part;
              count++;
              continue;
            }
          }
          result += part;
        }
        return result;
      };
    
      const formattedRemarks = insertInitialBreaks(remarks);
      const lines = formattedRemarks.split('\n');
    
      return (
        <div>
          {lines.map((line, index) => (
             <p key={index} dangerouslySetInnerHTML={{ __html: line || '&nbsp;' }}></p>
          ))}
        </div>
      );
    };
      
    return (
        isLoaded && (
          <Box>
          <VStack spacing={5}>
            {jsonData &&profitLossEntry&&businessModelEntry&& (
              <div>
              <VStack spacing={5}>
                <Heading size={2}>
                  <VStack>
                      <Text fontSize="20px">{fileName}</Text> 
                      <Text fontSize="20px">{thisUsername}</Text>
                      {imageUrl && <img src={imageUrl} alt="Uploaded file" />}
                  </VStack>
                </Heading>

                <BusinessModelAccordion businessModelEntries={businessModelEntry}/>
                <ShowModel modelTitle="Profit Loss Map" ModelEntries={profitLossEntry} />

                <Box>
                  {/* <Text>{remarks}</Text> */}
                  <Remarks remarks={remarks} />
                    {/* <Editor editorState={editorState} readOnly={true} /> */}
                </Box>

                </VStack>
              </div>
            )}
            
            <Text>
             {hashCompare()}
            </Text>
          </VStack>
          </Box>
        )

      );
      
}
export default ReviewIdea;
