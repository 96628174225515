import React,{useEffect} from 'react';
import { CircularProgress, Box, Modal, ModalOverlay, ModalContent, Text } from '@chakra-ui/react';

const WaitView = ({ viewStatus}) => {


  return (
        <Modal isOpen={true} isCentered size="xs">
        <ModalOverlay />
        <ModalContent>
        <Box display='flex' justifyContent='center' p='6'>
          {viewStatus === true && <CircularProgress isIndeterminate color="green.300" />}
        </Box>
        </ModalContent>
        </Modal>
  );
};

export default WaitView;


