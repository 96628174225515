import React, { useState } from 'react';
import { Input, VStack, Button,Flex,Box,Text,HStack,FormLabel } from '@chakra-ui/react';


const ProfitLossInput = ({profitLossInput, setProfitLossInput,parentProfitLoss}) => {

  const items_inEnglish = [
    'revenue',
    'cost',
    'grossMargin',
    'expense',
    'salesProfit'
  ]

  const items_dict_en2ja = {
    'revenue': "売上",
    'cost': "原価",
    'grossMargin': "粗利",
    'expense': "経費",
    'salesProfit': "利益"
  }


    ///parentを表示する！


  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfitLossInput({ ...profitLossInput, [name]: parseFloat(value) });
  };



  return (
    <form >
        <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        h="3vh"
        marginBottom="40px"
        >
        <Text fontSize="20px" marginBottom="20px">損益計算を記載ください</Text>
        <Box w="100%" p={2} borderRadius="md" bg="white">
            <form >
            <VStack spacing={4}>
              <HStack spacing={15}>
                      <FormLabel>Parent Model</FormLabel>
                      <FormLabel>New Input</FormLabel>
                    </HStack>
                {items_inEnglish.map((label)=>{
                  return(
                  <Flex direction="row" alignItems="center">
                     <HStack key={`hstack-${label}`}>
                    <Box height="2.5rem" width="5rem">
                     <FormLabel>{items_dict_en2ja[label]}</FormLabel>
                    
                    </Box>
                    <Box height="2.5rem" width="5rem">
                    <Text >{parentProfitLoss[label]}</Text>
                    
                    </Box>
                    <Box height="2.5rem" width="10rem">
                    <Input placeholder={label} name={label} value={profitLossInput[label] || ''} type="number" onChange={handleChange} />
                    </Box>
                    </HStack>
                  </Flex>)   
                })}
               
            </VStack>
            </form>
        </Box>
        </Flex>
    </form>
  );
};

export default ProfitLossInput;
