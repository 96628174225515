
import '../assets/App.css';
import React, { useState, useEffect } from "react";
import  {ethers}  from 'ethers'
import { Box, Link, Flex, Button, Input, Alert, AlertIcon} from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext';
import WaitTransaction from '../services/WaitTransaction';
import { Link as ChakraLink } from "@chakra-ui/react";
import { NavLink,useNavigate } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Members } from '../models';


function OwnerPage(){
    const navigate = useNavigate();
    const {chainId,account,checkChainId,checkMetaMaskInstalled, contractWithSigner} = useMetaMask();
       
    const [ifOwner,setIfOwner] = useState(false);
    const [inputAccount, setInputAccount] = useState('');
    const [inputUsername, setInputUsername] = useState('');
    const [error, setError] = useState(null); // 追加
    const [transactionStatus, setTransactionStatus] = useState(null);//追加


    ///アカウント情報やチェーン情報が変わったら最初のページに遷移させる！
    useEffect(() => {
      if (!account || !chainId) {
        navigate('/');
      }
    }, [account, chainId, navigate]);


    //smart contractにaddressを登録。
    const registerOnContract = async (account) => {
        const tx = await contractWithSigner.register(account);
        await tx.wait();
    }
      
      
    //AWS dynamoDBにaddressとusernameを登録。
    const createUserDB = async (address, username) =>{
        await DataStore.save(
          new Members({
          "address": address,
          "username": username
        }))
        console.log('upload on dynamoDB successfull')
    }

    ///handleClick関数　addressは小文字化する。
    const handleRegister = async (address, username) => {
      const addressKomoji = address.toLowerCase();//全て小文字で！！
      try {
        setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット
        await createUserDB(addressKomoji,username);
        await registerOnContract(address);//ethereumでは大文字小文字を識別しないのでどちらを送っても構わない。
        setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット

      }catch(error){
        console.error(error);
        setError(error.message); // エラーを追加
        setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
      }
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1500); // 1500ミリ秒（1.5秒）待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
    }
      
      
  
      return(
          <ChakraProvider>
            <ChakraLink as={NavLink} to="/" m={4}>Home</ChakraLink>
            <>
            {transactionStatus && (
              <WaitTransaction transactionStatus={transactionStatus} />
            )} {/* 追加 */}

            {error && 
              <Box role="alert">
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
               </Box>
           } {/* 追加 */}

            <Flex direction="column" align="center" bg="gray.100" color="blue.900" minH="10vh" p={5}>            <Input mt={2} placeholder="Enter Account" value={inputAccount} onChange={e => setInputAccount(e.target.value)} />
            <Input mt={2} placeholder="Enter Username" value={inputUsername} onChange={e => setInputUsername(e.target.value)} />
            <Button mt={2} onClick={() => handleRegister(inputAccount,inputUsername)}>
                Register Account & UserName.
            </Button>

            </Flex>
            </>
      </ChakraProvider>
  
      );
  }

  export default OwnerPage;