
import '../assets/App.css';
import React, { useState, useEffect } from "react";
import  {ethers}  from 'ethers'
import { Box, Link, Flex, Heading, Button, Input, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext';
import { Link as ChakraLink } from "@chakra-ui/react";
import { NavLink,useNavigate } from 'react-router-dom';
import HamburgerMenu from '../components/HumbergerMenu';

import { useRef } from 'react';
import { usePieChart } from '../utils/usePieChart'; 
import TokenPieChart from '../components/Jobs/TokenPieChart';
function MyPage(){
  
    

    return(
      <TokenPieChart/>
    );
  }
  
  export default MyPage;