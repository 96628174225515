import React, { useState, useEffect,useRef } from "react";
import { Pie,  getDatasetAtEvent, getElementAtEvent,getElementsAtEvent,Doughnut } from 'react-chartjs-2';

import { Text, Box, Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, UnorderedList, ListItem } from "@chakra-ui/react";
import { useMetaMask } from "../../services/MetaMaskContext"; 
import WaitView from "../../services/WaitView";
// ここにコントラクトのABIとアドレスを追加

export default function TokenPieChart() {
  const [data, setData] = useState({
    labels: ['Job Tokens','Eval Tokens'],
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#36A2EB', '#FFCE56']
      }
    ]
  });
  // チャートのデータと設定
  const [chartData, setChartData] = useState({
    datasets: []
  });
  const [evalPortfolio,setEvalPortfolio] = useState([{ideaId:0,value:0}]);
  const [jobPortfolio,setJobPortfolio] = useState([{ideaId:0,value:0}]);
  const [whichPortfolio,setWhichPortfolio] = useState([{ideaId:0,value:0}])
  const [modalHeader, setModalHeader] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoaded,setIsLoaded] = useState(false)

  const chartRef = useRef(null);  // 追加: チャートのref



  const {contractWithSigner,viewStatus,setViewStatus} = useMetaMask();

  
  useEffect(() => {
    async function fetchData() {
      setViewStatus(true)
    
      const totalEvalTokensBN = await contractWithSigner.getMyTotalEvalTokens();
      const totalEvalTokens = totalEvalTokensBN.toNumber();
      const totalJobTokensBN = await contractWithSigner.getMyTotalJobTokens();
      const totalJobTokens = totalJobTokensBN.toNumber();

      

      setData({
        labels: ['Eval Tokens', 'Job Tokens'],
        datasets: [
          {
            data: [totalJobTokens,totalEvalTokens],
            backgroundColor: ['#FF6384', '#36A2EB'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB']
          }
        ]
      }
      
      );

      setIsLoaded(true);

      // ポートフォリオデータのフェッチ
      const [evaldIdeas, evalToken] = await contractWithSigner.getMyEvalPortfolio();
      const ePort = evaldIdeas.map((ideaId, idx) => ({
        ideaId: ideaId.toNumber(),
        value: evalToken[idx].toNumber()
      }));
      setEvalPortfolio(ePort);
    
      const [jobbedIdeas, jobToken] = await contractWithSigner.getMyJobPortfolio();
      const jPort = jobbedIdeas.map((ideaId, idx) => ({
        ideaId: ideaId.toNumber(),
        value: jobToken[idx].toNumber()
      }));
      setJobPortfolio(jPort);


      // データセットの準備
      const outerData = [totalJobTokens, totalEvalTokens]; // これは外側の円に表示するトータル値です
      const innerData = jobPortfolio.concat(evalPortfolio).map(item => item.value); // これは内側の円に表示する個々のポートフォリオ値です
      const innerLabels = jobPortfolio.concat(evalPortfolio).map(item => `Idea ${item.ideaId}`);

      setChartData({
        labels: innerLabels,
        datasets: [
          {
            label: 'Total Tokens',
            data: outerData,
            backgroundColor: ['#36A2EB', '#FFCE56'], // 外側の円の色
            hoverBackgroundColor: ['#36A2EB', '#FFCE56'],
            // このデータセットは外側の円を表します
            weight: 1
          },
          {
            label: 'Portfolio Details',
            data: innerData,
            backgroundColor: [...Array(innerData.length).keys()].map(() => '#FF6384'), // 内側の円の色
            hoverBackgroundColor: [...Array(innerData.length).keys()].map(() => '#FF6384'),
            // このデータセットは内側の円を表します
            weight: 1
          }
        ]
      });
      // チャートデータの準備
      const chartData = {
        labels: evaldIdeas.map((idea, idx) => `Idea ${idea.toNumber()}`),
        datasets: [
          {
            label: 'Eval Tokens',
            data: evalToken.map(token => token.toNumber()),
            backgroundColor: '#FF6384'
          },
          {
            label: 'Job Tokens',
            data: jobToken.map(token => token.toNumber()),
            backgroundColor: '#36A2EB'
          }
        ]
      };
      setData(chartData);

      setViewStatus(false);
    }

    fetchData();
  }, []);



  const handleSegmentClick = async (event) => {
    const { current: chart } = chartRef;
    const clickedElements = getElementAtEvent(chart,event); // 変更

    const index = clickedElements[0].index;

    if (index === 1) {  // Assuming evalToken is the second segment in the pie chart
    
      setModalHeader('Eval Token Details');
      setWhichPortfolio(evalPortfolio);
      setModalOpen(true);
    } else if (index === 0) {  // jobToken segment

      setModalHeader('Job Token Details');
      setWhichPortfolio(jobPortfolio);
      setModalOpen(true);
    }
  };


  const chartOptions = {
    tooltips: {
      callbacks: {
        // ツールチップのラベルをカスタマイズする
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue);
          // 外側のデータセットの場合
          if (dataset.label === 'Total Tokens') {
            const label = data.labels[tooltipItem.index];
            return `${label}: ${tooltipItem.yLabel} (${((tooltipItem.yLabel / total) * 100).toFixed(2)}%)`;
          }
          // 内側のデータセットの場合
          else {
            const ideaId = data.labels[tooltipItem.index].match(/Idea (\d+)/)[1];
            return `Idea ID: ${ideaId}, tokens: ${tooltipItem.yLabel}`;
          }
        }
      }
    }
  };



  return (
    <Flex direction="column" align="center">
    {viewStatus&&<WaitView viewStatus={viewStatus}/>}
      <Box width="50%">
        {isLoaded && <Doughnut data={chartData} options={chartOptions} // ここでオプションを渡します
        />}
      </Box>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList>
              {whichPortfolio&&whichPortfolio.map((item, idx) => (
                <ListItem key={idx}>Idea ID: {item.ideaId}, tokens: {item.value}</ListItem>
              ))}
            </UnorderedList>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
