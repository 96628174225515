import { useState } from 'react';
import { Tooltip,Box, Stack,Text,Button, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { BiSolidEditAlt } from "react-icons/bi";
import EditIdea from './EditIdea';

function EditIdeaButton({tmpIdea  }) {
    const [isOpen, setIsOpen] = useState(false);
    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);


    return (
      <>
        <Tooltip label="子アイデアの追加" placement="right-start">
         <Button 
          colorScheme="teal" 
          onClick={onOpen} 
          borderRadius="full"
          _hover={{
            backgroundColor: "teal.400", // 2. ボタンの色が変わる
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
          }}
        >
            <BiSolidEditAlt size="1em" /> 
        </Button>
        </Tooltip>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxWidth="800px">
            <ModalHeader
             textAlign="center">
                アイデアを編集しよう
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <EditIdea onClose={onClose} tmpIdea={tmpIdea} />
            </ModalBody>
            <ModalFooter
                justifyContent="center"
            >
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
}

export default EditIdeaButton;