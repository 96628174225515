import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw,Modifier } from 'draft-js';
import  {Storage } from 'aws-amplify';
import {stateToHTML} from 'draft-js-export-html';
import { useMetaMask } from '../services/MetaMaskContext';
import { useDropzone } from 'react-dropzone';
import { HStack,Spacer,VStack,InputGroup,InputRightAddon,Box, Button,Select, Text, Alert, AlertIcon, Image, Input,FormControl, FormLabel, Flex,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
Textarea}  from "@chakra-ui/react";
import { sha256 } from 'js-sha256';
import FileDropzone from './FIleDropZone';
import WaitTransaction from '../services/WaitTransaction';
import ProfitLossInput from './ProfitLossInput';
import BusinessModelInput from './BusinessModelInput';
import { DataStore } from '@aws-amplify/datastore';
import { ProfitLossMap,BusinessModelMap,TmpIdea } from '../models';

 
export default function NewIdea ({parentId, onClose,parentIdeaURI})  {
  const [uploaded, setUploaded] = useState(false);
  const [fileName,setFileName] = useState("")
  const [imageFile, setImageFile] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null); // 追加
  const [transactionStatus, setTransactionStatus] = useState(null);//追加
  const [entries, setEntries] = useState([{ username: '', address: '',proportion: 10}]);
  const [remarks,setRemarks] = useState('')
  const [profitLossInput, setProfitLossInput] = useState({
    'revenue': 0,
    'cost': 0,
    'grossMargin': 0,
    'expense': 0,
    'salesProfit': 0
  })
  const [businessModelInput, setBusinessModelInput] = useState({})
  const [parentProfitLossInput, setParentProfitLossInput] = useState({
    'revenue': 0,
    'cost': 0,
    'grossMargin': 0,
    'expense': 0,
    'salesProfit': 0
  })
  const [parentBusinessModelInput, setParentBusinessModelInput] = useState({
    'customerSegment':{},
    'valueProposition':{},
    'channel':{},
    'relationshipWithCustomers':{},
    'incomeFlow':{},
    'resources':{},
    'activities':{},
    'partners':{},
    'costStructure':{}
    }
  )

  const {contractWithSigner,username,account,setKey,setKeyState,mapUsernameToAddress,mapAddressToUsername,filterModelsByIdeaURI,allBusinessModelMap,allProfitLossMap} = useMetaMask();///DynamoDBからusernameを取得している。これをideaURIに転用。
  const [isConfirmModalOpen,setConfirmModalOpen] = useState(false)
  const onConfirmModalClose = () =>{setConfirmModalOpen(false)}
  const onConfirmModalOpen = () => {setConfirmModalOpen(true)}

  const [isTmpSaveModalOpen,setTmpSaveModalOpen] = useState(false)
  const onTmpSaveModalClose = () =>{setTmpSaveModalOpen(false)}
  const onTmpSaveModalOpen = () => {setTmpSaveModalOpen(true)}

  const [isModelFetched, setModelFetched] = useState(false)

  useEffect(() => {
    if (!isModelFetched&&parentId>0) {
      console.log('fetching parent Models...')
      const parentBusinessModel = filterModelsByIdeaURI(allBusinessModelMap,parentIdeaURI)
      setParentBusinessModelInput(parentBusinessModel)
      const parentProfitLoss = filterModelsByIdeaURI(allProfitLossMap,parentIdeaURI)
      setParentProfitLossInput(parentProfitLoss)
      setModelFetched(true)
    }
  }, []);

  


  const handleMemberSelect = (index, username) => {
    let address = "";
    if (username !== "") {
      address = mapUsernameToAddress[username];
    }
  
    // 既に同じアドレスが選択されているかチェック
    const isDuplicateAddress = entries.some((entry, i) => i !== index && entry.address === address);
    if (isDuplicateAddress) {
      alert('このアドレスは既に選択されています。');
      return; // ここで処理を中断
    }
  
    updateEntry(index, 'username', username);
    updateEntry(index, 'address', address);
  };

  const addEntry = () => {
    const lastEntry = entries[entries.length - 1];
    if (lastEntry.address.trim() !== '') {
      setEntries([...entries, { username: '', address: '', proportion: 10 }]);
    }
    console.log(entries);
  };

  const updateEntry = (index, key, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][key] = value;
    if (updatedEntries.length > 1 && updatedEntries[index].username.trim() === '' &&
        updatedEntries[index].address.trim() === '' && updatedEntries[index].proportion === 10) {
      updatedEntries.splice(index, 1);
    }
    setEntries(updatedEntries);
  };





  const onDrop = useCallback(acceptedFiles => {
    // Instead of uploading the files, just save them to state
    setImageFile(acceptedFiles[0]);
    setPreviewImage(URL.createObjectURL(acceptedFiles[0]));
    console.log('acceptedFiles',acceptedFiles)
    console.log(acceptedFiles[0])
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/*,video/*'})
  

  const handleTmpSave_Fisrt = async () => {
    console.log(username)

    const date = new Date();
    const datetimeString = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
    const newFileName = `${username}_${fileName}_${datetimeString}`;
    //const newImageFileName = `ideas/tmp/${newFileName}/${newFileName}${imageFile.name.slice(imageFile.name.lastIndexOf('.'))}`;
    const jsonFileName = `ideas/tmp/${newFileName}/${newFileName}.json`;
    
    const addressArray = entries.map(entry => entry.address);
    const proportionArray = entries.map(entry => entry.proportion);
    const businessModelToSave = Object.keys(businessModelInput).reduce((acc, key) => {
      try{
        acc[key] = JSON.stringify(businessModelInput[key]);
      } catch (e){
        acc[key] = businessModelInput[key]
      }
      return acc;
    }, {});
    businessModelToSave.ideaURI = newFileName
    profitLossInput.ideaURI = newFileName
    const remarksContent = remarks;//string型
    const combinedObject = {
      title:fileName,
      businessModel: businessModelToSave,
      profitAndLoss: profitLossInput,
      remarks: remarksContent
    };
    const combinedJsonString = JSON.stringify(combinedObject);
    const dataToSave = {
      title: fileName,
      user: username,
      address: account,
      combinedContent:combinedJsonString,
      parentId: parentId,
      jobbers:addressArray,
      proportion:proportionArray
      };

    const rawAllContentStr = JSON.stringify(dataToSave);

    const newTmpIdeaInput = {
      ideaURI:newFileName,
      address: account,
      isTemporary: true,
      parentIdeaURI:parentIdeaURI
    }

      
    try{
      setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット
      const savedTmpIdea = await DataStore.save(new TmpIdea(newTmpIdeaInput))
      console.log('TMP Idea',savedTmpIdea)
      const savedProfitLossMapObject = await DataStore.save(new ProfitLossMap(profitLossInput))
      const savedBusinessModelMapMapObject = await DataStore.save(new BusinessModelMap(businessModelToSave))

      await Storage.put(jsonFileName, rawAllContentStr);
      //await Storage.put(newImageFileName, imageFile);

      setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      setKey(prevKey => prevKey + 1);
      setKeyState(true);
    } catch (error){
      console.error('Error uploading file', error)
      setError(error.message); // 追加
      setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      return;
    }

  }

  const handleSave = async () => {
    // Imageファイルチェック
    if (!imageFile || !imageFile.name) {
      alert('Please drop a file before saving.');
      return;
    }

    // Get current datetime
    const date = new Date();
    const datetimeString = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
     
        // Create the file name using the user's name and the current datetime
        //jobsフォルダに入れることにしてみる
    
    const newFileName = `${username}_${fileName}_${datetimeString}`;
    const jsonFileName = `ideas/${newFileName}/${newFileName}.json`;

    const newImageFileName = `ideas/${newFileName}/${newFileName}${imageFile.name.slice(imageFile.name.lastIndexOf('.'))}`;


    const addressArray = entries.map(entry => entry.address);
    const proportionArray = entries.map(entry => entry.proportion);

    const businessModelToSave = Object.keys(businessModelInput).reduce((acc, key) => {
      try{
        acc[key] = JSON.stringify(businessModelInput[key]);
      } catch (e){
        acc[key] = businessModelInput[key]
      }
      return acc;
    }, {});

    businessModelToSave.ideaURI = newFileName
    profitLossInput.ideaURI = newFileName


    //const contentState = editorState.getCurrentContent();
    //const htmlContent = stateToHTML(contentState);

    const remarksContent = remarks;//string型

    // 新しいJSONオブジェクトを作成
    const combinedObject = {
      title:fileName,
      businessModel: businessModelToSave,
      profitAndLoss: profitLossInput,
      remarks: remarksContent
    };
    const combinedJsonString = JSON.stringify(combinedObject);

    console.log('combinedJsonString',combinedJsonString)
    // The HTML content can now be saved as a string
    //const htmlContentStr = JSON.stringify({content: htmlContent});
    //console.log('htmlContentStr',htmlContentStr)
    // Create a hash of the rawContentStr
    const contentHash = sha256(combinedJsonString);
    //const contentHash = sha256(htmlContentStr);
    const bytes32Hash = "0x" + contentHash;

    setError(null); // 追加

    // S3とBlockchainへの保存
    try {
      setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット
      const tx_kai = await contractWithSigner.addIdea(newFileName,parentId,bytes32Hash,addressArray)
      const receipt = await tx_kai.wait(); // トランザクションのレシートを取得 

      console.log('Blockchain uploaded successfully');

      const dataToSave = {
        title: fileName,
        user: username,
        address: account,
        hashTags: bytes32Hash,
        //content: rawContentStr,
        combinedContent:combinedJsonString,
        //ideaId: newIdeaId,///ブロックチェーンからIdeaIDを取得して入れる。
        parentId: parentId,
        jobbers:addressArray,
        proportion:proportionArray
        };

      const rawAllContentStr = JSON.stringify(dataToSave);
      
      //upload to S3
      const savedProfitLossMapObject = await DataStore.save(new ProfitLossMap(profitLossInput))
      const savedBusinessModelMapMapObject = await DataStore.save(new BusinessModelMap(businessModelToSave))
      console.log('DynamoDB, ProfitLoss,',savedProfitLossMapObject)
      console.log('DynamoDB, BusinessModel',savedBusinessModelMapMapObject)
      console.log("DynamoDB data uploaded successfully.")
      await Storage.put(jsonFileName, rawAllContentStr);
      await Storage.put(newImageFileName, imageFile);
      console.log('S3 File uploaded successfully');
      setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
    
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      setKey(prevKey => prevKey + 1);
      setKeyState(true);

    } catch (error){
      console.error('Error uploading file', error)
      setError(error.message); // 追加
      setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
      
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose();
      return;

    } 

  };


    // Function to render the input values
    



    const renderInputValues_BM = () => {
      return (
        
        
        Object.entries(businessModelInput).map(([key, values]) => (
        <Box key={key} mb={4}>
          <Text >{key}</Text>
          {Object.entries(values).map(([number, value]) => (
            <Text key={number}>{`${number}: ${value}`}</Text>
          ))}
        </Box>
      ))
      );
    };

    const renderInputValues_PL = () => {
      return (
        

        Object.entries(profitLossInput).map(([key,value]) => (
          <HStack spacing={5}>
            <Box width="8rem">
            {key}:
            </Box>
            <Text>{value}</Text>
          </HStack>
        ))
        
      )
    }



  return (
    <>
    <Box className="App" pt="30px" pb="30px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <HStack spacing={20}>
    <Button 
      marginBottom="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onConfirmModalOpen}
      >下記全て記入の上、評価をお願いする
      </Button>
      <Button 
      marginBottom="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onTmpSaveModalOpen}
      >一時保存
      </Button>
      </HStack>
                
      {transactionStatus && (
  <WaitTransaction transactionStatus={transactionStatus} />
)} {/* 追加 */}


    <FormControl w="500px" display="flex" flexDirection="row" alignItems="center" mb="20px">
        
        <Input placeholder="タイトルを入力(スペースなし）" onChange={event => setFileName(event.target.value)} value={fileName} />
    </FormControl>
    <Box w="400px" h="auto" marginTop="10px">
      <FileDropzone onDrop={onDrop} isDragActive={isDragActive} uploaded={uploaded} previewImage={previewImage}  />
    </Box>
    <VStack margin="auto" width="100%" maxWidth="1080" style={{ display: 'flex', flexDirection: 'column', gap: '5rem' }}>
      <BusinessModelInput businessModelInput={businessModelInput} setBusinessModelInput={setBusinessModelInput} parentBusinessModel={parentBusinessModelInput}/>
      <ProfitLossInput profitLossInput={profitLossInput} setProfitLossInput={setProfitLossInput} parentProfitLoss={parentProfitLossInput}/>
      <Textarea placeholder='アイデアの概要を入力' size="md" height="200px" width='100%' mt='100px' value={remarks} onChange={e=>setRemarks(e.target.value)} />
    </VStack>
   

    <VStack spacing={4} width="600px" margin="auto" mt={10}>
    {entries.map((entry, index) => (
    <HStack key={index} width="100%" spacing={5}>
        <FormControl id={`address-${index}`} flex={1}>
            <FormLabel>Address {index + 1}</FormLabel>
            <Select 
                value={entry.username}
                onChange={(e) => handleMemberSelect(index, e.target.value)}
            >
                <option value="">-- Jobberをプルダウンから選択 --</option>
                {Object.keys(mapUsernameToAddress).map(username => (
                    <option key={username} value={username}>
                        {username}
                    </option>
                ))}
            </Select>
        </FormControl>
        <FormControl id={`proportion-${index}`} flex={1}>
            <FormLabel>Proportion</FormLabel>
            <Select 
              value={entry.proportion}
              onChange={(e) => updateEntry(index, 'proportion', parseInt(e.target.value))}
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(value => (
                <option key={value} value={value}>
                  {value}%
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
      ))}
      <HStack>
      <Button onClick={addEntry} colorScheme="blue" size="md">
        他のJobberを追加
      </Button>

      </HStack>
    </VStack>

    <Modal isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="3xl" fontWeight="bold">ARE YOU READY ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <HStack>
            <Text fontWeight="bold">Title:</Text><Text> {fileName}</Text>
            </HStack>
            <Text fontWeight="bold">BusinessModel</Text>
            {renderInputValues_BM()}
            <Text fontWeight="bold">Profit&Loss</Text>
            {renderInputValues_PL()} */}
            {/* ここに他のvalueの確認を入れる */}
            {/* <Text fontWeight="bold">概要</Text>
            <Text>{remarks}</Text> */}
            <VStack>
            <Text fontWeight="bold">評価をお願いすると、</Text>
            <Text fontWeight="bold">アイデアがブロックチェーンに書き込まれます。</Text>
            <Text fontWeight="bold">編集・変更できなくなりますが、OKですか？</Text>
            </VStack>

          </ModalBody>
                 
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onConfirmModalClose}>
              閉じる
            </Button> */}
            <Button  
            onClick={() => {
              handleSave();
              onConfirmModalClose();
            }} 
            color="white" 
            bg="teal.500" 
            _hover={{bg:"teal.300" }}>
              評価をお願いする
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    <Button 
      marginTop="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onConfirmModalOpen}
      >評価をお願いする
      </Button>


      <Modal isOpen={isTmpSaveModalOpen} onClose={onTmpSaveModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>一時保存します。</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
            <Text>ブロックチェーンには書き込まれません。</Text>
            <Text>サムネイルは保存されません。</Text>
            </VStack>
          </ModalBody>
                 
          <ModalFooter>

            <Button colorScheme="blue" mr={3}  onClick={() => {
              handleTmpSave_Fisrt();
              onTmpSaveModalClose();
            }}>
              一時保存 
             
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    <Button 
      marginTop="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onTmpSaveModalOpen}
      >一時保存
      </Button>
                
    </Box>
    </>
  );
};
