import { useState, useEffect } from 'react';
import { Box, Button, Select, Text, Alert, AlertIcon, Image, Input, FormControl, FormLabel, Flex, VStack, Heading, HStack }  from "@chakra-ui/react";
import  {Amplify, Storage } from 'aws-amplify';
import awsconfig from "../../aws-exports";
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'draft-js';
import {ethers} from "ethers";
import { FaCheckDouble } from 'react-icons/fa';
import { stateFromHTML } from 'draft-js-import-html';


Amplify.configure(awsconfig);

const Review = ({isLoaded, idea,thisUsername }) => {

    const [jsonData, setJsonData] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [s3Hash, setS3Hash] = useState(null)
    const [fileName, setFileName] = useState(null)

    function extractText(str) {
        const match = str.match(/_(.*?)_/);
        if (match) {
            return match[1];
        }
        return null;
    }
    
    const handleLoad = async () => {
        try {
          const jsonFileName = idea[2]
          const etherFileName = extractText(jsonFileName)
          setFileName(etherFileName)
          const filesInDirectory = await Storage.list(jsonFileName);
          for (const file of filesInDirectory.results) {
            const fileUrl = await Storage.get(file.key);  // Get file URL
        
            if (file.key.endsWith('.json')) {
              // fetch the JSON file and save it to state
              fetch(fileUrl)
                .then(response => response.json())
                .then(data => {
                    setJsonData(data);  // Update jsonData state
                    
                    //htmlから。
                    const { content: htmlContent } = JSON.parse(data.contentHtml)
                    // Convert the HTML to Draft.js ContentState
                    console.log(htmlContent)
                    const contentState = stateFromHTML(htmlContent);
                    console.log(contentState)
                    const editorState = EditorState.createWithContent(contentState);   

                    setEditorState(editorState);  // Update editorState
                    const etherHash = ethers.utils.hexlify(data.hashTags)
                    setS3Hash(etherHash);
                });
            } else if (file.key.endsWith('.jpg') || file.key.endsWith('.png')|| file.key.endsWith('.jpeg')) {
              // Save the image URL to state
              setImageUrl(fileUrl);
            }
          }
        } catch (error) {
          console.error('Error fetching files', error);
        }
    };

    

    function hashCompare() {
        let bytes32Hash = ethers.utils.hexlify(idea[5]);
        if (bytes32Hash === s3Hash) {
            return (<HStack><FaCheckDouble size={30}/><Text>No Alteration Detected on Blockchain</Text><FaCheckDouble size={30}/></HStack>)
        } else {
            return 'データに変更があります';
        }
    }

    useEffect(() => {
      if (isLoaded) {
        handleLoad().then(hashCompare);
      }
    }, [isLoaded]);
      
    return (
        isLoaded && (
          <Box>
          <VStack spacing={5}>
            {jsonData && (
              <div>
              <VStack spacing={5}>
                <Heading size={2}>
                  <VStack>
                      <Text>{fileName}</Text>
                      <Text>{thisUsername}</Text>
                  </VStack>
                </Heading>
                <Box>
                    <Editor editorState={editorState} readOnly={true} />
                </Box>

                </VStack>
              </div>
            )}
            {imageUrl && <img src={imageUrl} alt="Uploaded file" />}
            <Text>
             {hashCompare()}
            </Text>
          </VStack>
          </Box>
        )

      );
      
}
export default Review;
