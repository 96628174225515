import { useState,useEffect,FC } from 'react';
import { useDisclosure,Box, VStack,HStack,Text,Button, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { IoAddCircle } from "react-icons/io5";
import NewIdea from './NewIdea';
import { useMetaMask } from '../services/MetaMaskContext';
import { Tree } from "react-d3-tree";
import extractText from "../utils/handleJsonURI";
import  WaitView  from '../services/WaitView';
import {BiSolidChevronRightCircle,BiSolidChevronDownCircle} from "react-icons/bi"
import ShowDistributionButton from './ShowDistributionButton';
import ShowScoreButton from './ShowScoreButton';
import IdeaBox from './IdeaBox';
import AddIdeaButton from './AddIdeaButton';
import { ProfitLossMap,BusinessModelMap,TmpIdea } from "../models"
import EvaluateButton from './EvaluateButton';
import ConfirmJobValueButton from "./ConfirmJobValueButton"
import IdeaCard from './IdeaCard';

function IdeaGroupsTree({ walletConnected }) {
  const {key,allIdea,getAllIdea,viewStatus,setViewStatus,isFirstRender,
    setIsFirstRender,keyState,setKeyState,getMyPortfolio,fetchMembers,makeMemberAddressMapFromSC,allProfitLossMap,setAllProfitLossMap,
    allBusinessModelMap,setAllBusinessModelMap,
    fetchAllModels,allTmpIdea,setAllTmpIdea,myTmpIdea,setMyTmpIdea,filterTmpModelsByAccount,account,getMyTmpIdea} = useMetaMask();

  const [ideaTreeData, setIdeaTreeData] = useState(null);
  const [activeNodeId, setActiveNodeId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedTree, setSelectedTree] = useState(null);
  const [singleTreeView,setSingleTreeView] = useState(false);
  const [allideaState,setAllIdeaState] = useState(false);


  const GetIdeasJobs = async() =>{
    setViewStatus(true)
    await setIsFirstRender(false);
    await getAllIdea();
    await getMyPortfolio();
    await fetchMembers();
    await makeMemberAddressMapFromSC();
    await fetchAllModels(BusinessModelMap,setAllBusinessModelMap);
    await fetchAllModels(ProfitLossMap,setAllProfitLossMap)
    await fetchAllModels(TmpIdea,setAllTmpIdea)
    //setMyTmpIdea(filterTmpModelsByAccount(allTmpIdea,account))
    await getMyTmpIdea()
    console.log('GetIdeasJobsPortfolios')
    setViewStatus(false)
  }

  useEffect(() => {
    // 初回レンダリングの後にフラグをfalseに設定
    const firstFetchAndGenTree = async () =>{
      await GetIdeasJobs();
      setAllIdeaState(true);
    }

    if (isFirstRender) {
      firstFetchAndGenTree();
    }
  }, []);

  useEffect(() => {
    // 初回レンダリングの後にフラグをfalseに設定
    if (allideaState) {
      ideaToTree();
      setAllIdeaState(false);
    }
  }, [allIdea,allideaState]);



  useEffect(() => {
    // 初回レンダリングではない時のみgetAllIdeaを実行
    const keyUpdate = async () => {
      await GetIdeasJobs();
      await ideaToTree();
      console.log('key',key,'keyState',keyState)
      setKeyState(false)
    }
    if (walletConnected && !isFirstRender && keyState) {
      keyUpdate();
    }
    
  }, [key]);



  const ideaToTree = async () => {
    try{
      const tree = generateTree(allIdea);
      const formattedTreeData = await formatDataForD3Tree(tree); // awaitを追加
      const validTreeData = formattedTreeData && formattedTreeData.filter(rootNode => rootNode.attributes.isValid);
      setIdeaTreeData(validTreeData);
      console.log('ideaToTree',allIdea)
    } catch(error){
      console.error(error);
      return;
    }
  }

  useEffect(() => {
    
    const keyUpdate = async () => {
      setSingleTreeView(false)
      await getAllIdea();
      console.log('keyUpdate',keyState)
      setKeyState(false)
      
    }
    const IdeaUpdate = async () =>{
      await keyUpdate();
      setAllIdeaState(true)
    }

    if (walletConnected && keyState){
      IdeaUpdate();
    } else {
      ideaToTree();
    }

  }, [key]);

  useEffect(() => {
    if (allideaState){
      ideaToTree();
      console.log('allIdea hook',allIdea)
      setAllIdeaState(false);
    }
  }, [allIdea]);



  // 配列の要素からparentIdに基づいてツリーを作成するヘルパー関数

  const generateTree = (array, parentId = 0) => {
    return array
      // parentIdが一致する要素だけをフィルタリングします
      .filter(idea => idea[1] === parentId)
      // フィルタリングされた要素をマップして新しいオブジェクトを作成します
      .map(idea => ({
        ...idea,
        // その要素を親とする子ノードを再帰的に生成します
        children: generateTree(array, idea[0])
      }));
  };
  



const formatDataForD3Tree = async (data, rootNodeId) => {
  return Promise.all(data.map(async (item) => {
    return {
      name: extractText(item[2]), // アイデアの名前
      attributes: {
        ID: item[0],
        parentId: item[1],
        tokenURI: item[2],
        proposer: item[3].toLowerCase(),
        totalEvalTokens: item[4],
        contentHash: item[5],
        isValid: item[6],
        totalJobTokens: item[7],
        totalScore: item[8],
        jobValue: item[9],
        rootNodeId: rootNodeId || item[0],
      },
      // 再帰的に子要素も非同期に処理します
      children: item.children ? await formatDataForD3Tree(item.children, rootNodeId || item[0]) : [],
    };
  }));
};

// ideaId,
// parentIds[index],
// ideaUris[index],
// proposers[index],
// totalEvalTokenss[index],
// contentHashes[index],
// flags[index],
// totalJobTokenss[index],
// totalScores[index],
// jobValues[index]

function extractIdeaFromNode(nodeDatum) {
  return [
    nodeDatum.attributes.ID,
    nodeDatum.attributes.parentId,
    nodeDatum.attributes.tokenURI,
    nodeDatum.attributes.proposer,
    nodeDatum.attributes.totalEvalTokens,
    nodeDatum.attributes.contentHash,
    nodeDatum.attributes.isValid,
    nodeDatum.attributes.totalJobTokens,
    nodeDatum.attributes.totalScore,
    nodeDatum.attributes.jobValue
  ];
}


  const treeStyles = {
    width: "80vw",
    height: "80vh",
    backgroundColor: "gray.100", // 背景色を設定
    margin: "auto", // 中央に配置するための設定
  };


    function CustumIdeaCard({idea}) {
      return (
          // <HStack spacing={2}>
          //    <IdeaBox idea={idea}/> {/* //width 450px */}
          //     <VStack spacing={1}>
          //       <AddIdeaButton idea={idea}/>
                
          //       <EvaluateButton ideaId={idea[0].toNumber()} ideaURI={idea[2]} />
          //       <ShowScoreButton ideaId={idea[0].toNumber()} ideaURI={idea[2]}/>
          //       <ConfirmJobValueButton ideaId={idea[0].toNumber()} jobValue={idea[9].toNumber()} proposer={idea[3].toLowerCase()}/>

          //       <ShowDistributionButton ideaId={idea[0].toNumber()}/>
                
          //     </VStack>
          // </HStack>
          <IdeaCard idea={idea}/>
      );
    }

    const CustomNodeElement = ({ nodeDatum, toggleNode }) => {
          
      const handleToggleClick = (event) => {
        event.stopPropagation();
        toggleNode(nodeDatum);
      }
    
      const idea = extractIdeaFromNode(nodeDatum);
      console.log('idea:',idea)
    
      return (
        <foreignObject x="-130" y="-95" width="600" height="300">
          <HStack spacing={1}>
            <CustumIdeaCard idea={idea} />
            {nodeDatum.children.length > 0 && 
              <Button onClick={handleToggleClick} colorScheme='green' 
                borderRadius="full"
                _hover={{
                  backgroundColor: "green.400", // 2. ボタンの色が変わる
                  boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
                }}>
                {nodeDatum.__rd3t.collapsed ? 
                  <BiSolidChevronRightCircle size="1.5em" /> : 
                  <BiSolidChevronDownCircle size="1.5em" />}
              </Button> 
            }
          </HStack>
        </foreignObject>
      );
    };

    const handleRootNodeClick = (nodeDatum) => {
        setSelectedTree(nodeDatum)
        setSingleTreeView(true)
    }

    const CustomRootNodeElement = ({nodeDatum})=> {
    
      const idea = extractIdeaFromNode(nodeDatum);
      console.log('idea',idea)
    
      const hasChildren = nodeDatum.children && nodeDatum.children.length > 0;
      
      return (
        <foreignObject  width="600" height="300">
          <VStack>
            <HStack spacing={1}>
              <CustumIdeaCard idea={idea} />
              <Button onClick={() => handleRootNodeClick(nodeDatum)} colorScheme='green' 
                borderRadius="full"
                _hover={{
                  backgroundColor: "green.400",
                  boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                }}
                visibility={hasChildren ? "visible" : "hidden"}  // ボタンが表示されるかどうかはここで制御します
              >
                続きを見る
              </Button>
            </HStack>
          </VStack>
        </foreignObject>
      );
    };
    

    function filterInvalidNodes(node) {
      if (node.attributes.isValid === false) {
        return null;  // isValidがfalseのノードは削除
      }
    
      const filteredChildren = node.children?.map(filterInvalidNodes).filter(Boolean) || [];
      return {
        ...node,
        children: filteredChildren,  // 子ノードも再帰的にフィルタリング
      };
    }
    
    function singleTree(rootNode) {
      const filteredRootNode = filterInvalidNodes(rootNode);
    
      return (
        <Box style={{overflow: 'auto', width: '100%', height: '100%'}}>
          {selectedTree && (
            <Button onClick={() => setSelectedTree(null)} colorScheme='blue'>
              全て表示
            </Button>
          )}
          <Tree
            data={filteredRootNode}
            nodeSvgShape={{ shape: 'none' }} 
            pathFunc="step"
            translate={{ x: 300, y: 300 }} // x, y値は適宜調整してください
    
            renderCustomNodeElement={(rd3tProps) =>
              <CustomNodeElement
                nodeDatum={rd3tProps.nodeDatum}
                toggleNode={rd3tProps.toggleNode}
              />
            }
            shouldCollapseNeighborNodes={node => node.id !== activeNodeId}
            depthFactor={700}
            zoomable={true}
            separation={{ siblings: 2, nonSiblings: 2 }} 
          />
        </Box>
      );
    }
    
  

    const rootNodesData = ideaTreeData && ideaTreeData.map((rootNode, index) => (
      <Box>
        <CustomRootNodeElement
        nodeDatum={rootNode}
       />
      </Box>
    ));

  return (

    <VStack spacing={5}>
       <Text>完全なる新規アイデアを追加するには下記をクリック</Text>
        <Button
          size="lg" // ボタンのサイズを大きくする
          colorScheme="teal"
          onClick={onOpen}
          borderRadius="full" // ボタンを円形にする
          _hover={{
            backgroundColor: "teal.400",
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
          }}
          style={{ width: "100px", height: "100px" }} // widthとheightを指定してボタンのサイズを調整する
        >
          <IoAddCircle size="3em"/>
        </Button>
         <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxWidth="800px">
            <ModalHeader
             textAlign="center">
                完全・新規アイデア
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <NewIdea parentId={0} onClose={onClose}/>
            </ModalBody>
            <ModalFooter
                justifyContent="center"
            >
            </ModalFooter>
          </ModalContent>
        </Modal>


      {viewStatus && (
        <WaitView viewStatus={viewStatus} />
      )} 
      
      <div style={treeStyles} >
        {ideaTreeData&&selectedTree&&singleTreeView&&allProfitLossMap ? (
          singleTree(selectedTree)
        ) : (
          ideaTreeData && rootNodesData
        )}
      </div>
      </VStack>

  );
}


export default IdeaGroupsTree;
