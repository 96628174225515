import { useEffect, useState } from 'react';
import { Box, VStack, Button, HStack, Heading, Text, useDisclosure, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    IconButton, Circle,Image
  } from '@chakra-ui/react';
import ReviewIdea from './ReviewIdea';
import { useMetaMask } from '../services/MetaMaskContext';
import { Tooltip } from "@chakra-ui/react";
import AddIdeaButton from './AddIdeaButton';
import InvalidateIdeaButton from './InvalidateIdeaButton';
import  {Amplify, Storage } from 'aws-amplify';
import ConfirmJobValueButton from './ConfirmJobValueButton';
import ShowDistributionButton from './ShowDistributionButton';
import EvaluateButton from './EvaluateButton';


export default function IdeaBox({ idea }) {
  const {isOwner,ifMyIdea,addressToUsername,contractWithSigner,mapUsernameToAddress,
    mapAddressToUsername,username,amIJobberOfIdea,ifIScored} = useMetaMask();
  const [isMyIdea, setIsMyIdea] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ideaOwner,setIdeaOwner] =useState('')
  const [fileName, setFileName] = useState(null)
  const [imageUrl, setImageUrl] = useState(null);
  const [amIJobber, setAmIJobber] = useState(false);
  const [jobbers, setJobbers] = useState([])
  const [businessModel,setBusinessModel] = useState();
  const [profitLossModel, setProfitLossModel] = useState();
  const [isConfirmed,setIsConfirmed] = useState(false)
  const [didIScore,setDidIScore] = useState(false)


  const onOpen = () => {
    setIsOpen(true);
    setIsLoaded(true);
  };

  const onClose = () => setIsOpen(false);

  useEffect(()=>{

    const checkIfIScored = async (ideaId) => {
      setDidIScore(await ifIScored(ideaId));
    }
    
    const checkIfMyIdea = async (ideaId) =>{
        const a = await ifMyIdea(ideaId);
        setIsMyIdea(a);
    }

    const fetchUsername = async (address) =>{
      const un = mapAddressToUsername[address.toLowerCase()];
      setIdeaOwner(un);
    }

    const checkAmIJobber = async (ideaId) => {
      setAmIJobber(await amIJobberOfIdea(ideaId));
    }

    const checkIsConfirmed = () => {
      setIsConfirmed(idea[9] > 0)
    }

    if (!isOwner){///ownerはifMyIdea関数にアクセスできない。
      checkIfMyIdea(idea[0])
      fetchUsername(idea[3])
      checkAmIJobber(idea[0])
      checkIsConfirmed()
      checkIfIScored(idea[0])
    }
  },[onOpen])
  

  useEffect(()=>{
    const loadImage= async (idea) => {
      try {
        const jsonFileName = idea[2]
        const etherFileName = extractText(jsonFileName)
        setFileName(etherFileName)
        const filesInDirectory = await Storage.list(`ideas/${jsonFileName}`);
        for (const file of filesInDirectory.results) {
          const fileUrl = await Storage.get(file.key);  // Get file URL
          if (file.key.endsWith('.jpg') || file.key.endsWith('.png')|| file.key.endsWith('.jpeg')) {
            // Save the image URL to state
            setImageUrl(fileUrl);
          }
        }
      } catch (error) {
        console.error('Error fetching files', error);
      }
    };


    if(mapAddressToUsername){
      amIJobberOfIdea(idea[0])
      getJobbers(idea[0]);
      loadImage(idea);
    }
  },[mapAddressToUsername])


  

  function extractText(str) {
    const match = str.match(/_(.*?)_/);
    if (match) {
        return match[1];
    }
    return null;
}






  const attribute = extractText(idea[2])


  // const amIJobberOfIdea = async (ideaId) => {
  //   const amIJobber = await contractWithSigner.amIJobberOfIdea(ideaId);
  //   setAmIJobber(amIJobber);
  // }  
  
  const getJobbers = async (ideaId) => {
    const _jobbers = await contractWithSigner.getJobbersOfIdea(ideaId);
    const convertedJobbers = _jobbers.map(address => mapAddressToUsername[address.toLowerCase()] || "");
    setJobbers(convertedJobbers);
  }

  return (
    <HStack spacing={5}>
    <Tooltip label="アイデア詳細を表示" placement="right-start">
      <Box
        onClick={onOpen} // Add the function here
        border="1px"
        borderColor="gray.200"
        borderRadius="lg"
        padding="5"
        boxShadow="lg"
        marginBottom="15px"
        marginTop="10px"
        width="450px"
        
        cursor="pointer" // Change the cursor to a pointer on hover
        position="relative" // 相対位置を指定
        backgroundColor="gray.50" // 背景色を薄い灰色に設定します
      >
        {/* ID番号を含むサークル */}
        <Circle
          size="40px"
          bg="teal.500"
          color="white"
          position="absolute" // 絶対位置を指定
          top={0} // ボックスの上端からの位置
          left={0} // ボックスの左端からの位置
          transform="translate(-10%, -10%)" // サークルを半分だけボックスの外に移動
        >
          <Text>{idea[0]}</Text>
        </Circle>

      <HStack spacing={3}>
      <Box
        width="200px"
        height="200px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {imageUrl && 
          <Image
            src={imageUrl}
            alt="Uploaded file"
            maxWidth="200px"
            maxHeight="200px"
            objectFit="cover" // これはオプションですが、画像のアスペクト比を保持しつつBox内に収めるのに役立ちます
          />
        }
      </Box>

      <Box width="300px">
        <VStack spacing={2} >
        
        <Text fontWeight={'bold'} align={'center'}>{attribute}</Text>
        
        {/* <Text fontWeight={'semibold'}>Proposed by: {ideaOwner}</Text> */}
        <HStack>
        {/* <>Proposed by: </> */}
        {jobbers&&jobbers.map((user, index) => (
        <Text fontWeight={"semibold"} key={index}>{user}</Text>
        ))}
        </HStack>
        {/* <Text>Score : {idea[8]}</Text> */}
        {/* <Text>JobValue : {idea[9]}</Text> */}
        <Text>EvalToken: {idea[4]}</Text>
        <Text>JobToken: {idea[7]}</Text>
        </VStack>
      </Box>
      </HStack>
      </Box>
      </Tooltip>

     

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxWidth="800px">
          <ModalHeader textAlign="center">

            アイデアレビュー

          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <VStack spacing={5}>
          <HStack spacing={5}>
            <AddIdeaButton idea={idea}/>
            {isMyIdea&&<InvalidateIdeaButton ideaId = {idea[0]} onClosePrev={onClose}/>} 
            {amIJobber&&!isConfirmed&&<ConfirmJobValueButton idea={idea} onClosePrev={onClose}/>}
            {!amIJobber&&!isConfirmed&&!didIScore&&<EvaluateButton idea={idea} onClosePrev={onClose}/>}
            {isConfirmed&&<ShowDistributionButton ideaId={idea[0]}/>}
          </HStack>
            <ReviewIdea isLoaded={isLoaded} idea={idea} thisUsername={ideaOwner} />
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent="center">
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>

  );
}