import React, { useState, useEffect } from 'react';
import {
    Box, VStack, Button, HStack, Heading, Text, useDisclosure, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    IconButton, Tooltip,Alert, AlertIcon,
  Input,
  Stack,
  FormControl,
  FormLabel,Select
} from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext'; // パスを適宜調整してください。
import WaitTransaction from '../services/WaitTransaction';
import {MdOutlineCreditScore} from "react-icons/md"
import  {Storage } from 'aws-amplify';


export default function ConfirmJobValueButton({idea,onClosePrev}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState(null); // 追加
  const [transactionStatus, setTransactionStatus] = useState(null);//追加
  const [jobbers, setJobbers] = useState([]);
  const [proportionArr,setProportionArr] = useState([])
  const [jobberAddrArr,setJobberAddrArr] = useState([])
  const [isConfirmed,setIsConfirmed] = useState(false)
  const { contractWithSigner, mapAddressToUsername,setKey,setKeyState,account,amIJobberOfIdea} = useMetaMask();

  useEffect(() => {
    // ジョバーのアドレスを取得して変換する
    const fetchJobbers = async () => {
      try {
        const jobberAddresses = await contractWithSigner.getJobbersOfIdea(idea[0]);
        const jobberUsernames = jobberAddresses.map(address => 
          mapAddressToUsername[address.toLowerCase()] || "Unknown"
        );
        setJobberAddrArr(jobberAddresses)
        setJobbers(jobberUsernames);
        
      } catch (error) {
        console.error("Failed to fetch jobbers:", error);
      }
    };
    const loadProportion = async () => {
      try {
        const ideaFileName = idea[2]
        const file = await Storage.get(`ideas/${ideaFileName}/${ideaFileName}.json`)
        fetch(file)
          .then(response=> response.json())
          .then(data => {
            setProportionArr(data.proportion)
            console.log('data',data)
          });
      } catch (error) {
        console.error('Error fetching files', error);
      }
  };
    if (!idea[9]){
        fetchJobbers();
        loadProportion();
    }
  }, [onOpen,mapAddressToUsername]);

  console.log('proportion',proportionArr)

  const handleProportionChange = (index, value) => {
    // 新しい配列を作成して指定された index の値を更新
    const newProportions = [...proportionArr];
    newProportions[index] = parseFloat(value) || 10; // 無効な入力の場合は0をセット
    setProportionArr(newProportions);
  };

  const handleSubmit = async () => {
    if(idea[3].toLowerCase() != account.toLowerCase()){
        alert('Only Idea Proposer Allowed to Confirm.');
        return;
    }
    if(idea[9]){
        alert('Already Cinfirmed!');
        return;
    }

    
    // const proportionsArray = Object.values(proportions).map(p => parseInt(p, 10));
    // const jobberAddresses = Object.keys(proportions).map(username => 
    //   Object.keys(mapAddressToUsername).find(key => mapAddressToUsername[key] === username)
    // );

    try {
      setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット

      const tx = await contractWithSigner.confirmJobValue(idea[0], jobberAddrArr, proportionArr);
      const receipt = await tx.wait();
      console.log('confirmation of JobValue succeeded on blockchain.');

      setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット
    } catch (error) {
      console.error("Failed to confirm job value:", error);
      setError(error.message); // 追加
      setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
    }
    setTransactionStatus(null); // 閉じたいのでnullにセット
    onClose(); // 追加: モーダルを閉じる
    onClosePrev();//追加 IdeaBoxのmodalを閉じる
    setKey(prevKey => prevKey + 1);
    setKeyState(true);
  };

  return (
    <>

        {transactionStatus && (
              <WaitTransaction transactionStatus={transactionStatus} />
        )} 
        {error && 
              <Box role="alert">
                <Alert status="error">
                  <AlertIcon />
                  {error}
                </Alert>
               </Box>
        } 

{<Tooltip label="Confirm JobValue" placement="right-start">
        <Button 
          colorScheme="teal" 
          onClick={onOpen} 
          borderRadius="full"
          _hover={{
            backgroundColor: "teal.400", // 2. ボタンの色が変わる
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
          }}
        >
          <MdOutlineCreditScore size="1.5em" /> 
        </Button>
        </Tooltip>}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>評価を確定し、JobTokenを受け取りましょう。
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>分配比率を変更できます。</Text>
            <Box p={4}>
              <form>
                <Stack spacing={4}>
                  {jobbers.map((jobber, index) => (
                    <FormControl key={index}>
                      <FormLabel>ユーザー: {jobber}</FormLabel>
                      <Select
                        value={proportionArr[index] || 10} // デフォルト値を10に設定
                        onChange={(e) => handleProportionChange(index, e.target.value)}
                      >
                        {[10, 20, 30, 40, 50, 60, 70, 80, 90].map((value) => (
                          <option key={value} value={value}>
                            {value}%
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
                </Stack>
              </form>
            </Box>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              確定
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
