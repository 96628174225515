import { createContext, useContext, useState, useEffect } from "react";
import { ethers } from 'ethers';
import { Auth } from 'aws-amplify';
import { DataStore } from '@aws-amplify/datastore';
import { Members,ProfitAndLoss,TmpIdea } from '../models';

//---------------Upgrade時はここを変更する！！！------------------//
import contractABI from "../artifacts/IdeaFlowBankUpgradeable_V2_2.json"; 


const MetaMaskContext = createContext();
const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;

export function MetaMaskProvider({ children }) {
  const SEPOLIA_CHAIN_ID = 11155111;

  // 各種ステートを定義
  const [chainId, setChainId] = useState(false);
  const [account, setAccount] = useState('');
  const [provider,setProvider] = useState(null);
  const [contractWithSigner,setContractWithSigner] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [isOwner,setIsOwner] = useState(false);
  const [username,setUsername] = useState('');
  const [walletConnected, setWalletConnected] = useState(false);
  const [key, setKey] = useState(0);
  const [allIdea,setAllIdea] = useState([])
  const [viewStatus,setViewStatus] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true); // 初回レンダリングをチェックするstate
  const [keyState,setKeyState] = useState(false)
  const [memberDataCache,setMembersDataCache] = useState();
  const [memberLoaded,setMemberLoaded] = useState(false);
  const [jobPortfolio,setJobPortfolio] = useState()
  const [evalPortfolio,setEvalPortfolio] = useState()
  const [scorePortfolio,setScorePortfolio] = useState()
  const [mapUsernameToAddress, setMapUsernameToAddress] = useState({});
  const [mapAddressToUsername,setMapAddressToUsername] = useState({})
  const [allBusinessModelMap,setAllBusinessModelMap] = useState()
  const [allProfitLossMap,setAllProfitLossMap] = useState()
  const [allTmpIdea,setAllTmpIdea] = useState()
  const [myTmpIdea,setMyTmpIdea] = useState()


  // その他必要なステートを追加...

  const ifIScored = async (ideaId) => {
    try{
      const ifScored = await contractWithSigner.ifIScored(ideaId);
      return ifScored
    } catch(err){
      console.error(err)
      return false
    }
  }

  const amIJobberOfIdea = async (ideaId) => {
    try{
      const amIJobber = await contractWithSigner.amIJobberOfIdea(ideaId);
      return amIJobber
    }catch(err){
      console.error(err)
      return false
    }
  }

  

  const updateModelByIdeaURI = async (Model, ideaURI, updateData) => {
    try {
      // 特定のideaURIに一致するモデルを検索
      const modelToUpdate = await DataStore.query(Model, c => c.ideaURI("eq", ideaURI));
      if (modelToUpdate.length > 0) {
        // 最初の一致するモデルを取得（一致するものは通常1つのみ想定）
        const model = modelToUpdate[0];
  
        // 更新内容を適用
        const updatedModel = await DataStore.save(
          Model.copyOf(model, updated => {
            Object.assign(updated, updateData);
          })
        );
  
        console.log('モデルが更新されました:', updatedModel);
        return updatedModel;
      } else {
        console.log('更新するモデルが見つかりませんでした。');
        return null;
      }
    } catch (error) {
      console.error('モデルの更新に失敗しました:', error);
      return null;
    }
  };


    const filterModelsByIdeaURI = (models, ideaURI) => {
      try {
        const model = models.find(model => model.ideaURI === ideaURI);
        console.log(`ideaURIが${ideaURI}に一致するモデル:`, model);
        return model;
      } catch (error) {
        console.error(`ideaURIが${ideaURI}に一致するモデルの検索に失敗しました:`, error);
        return undefined;
      }
    };

    const filterTmpModelsByAccount = (models,address) => {
      try {
        const model = models.filter(model => model.address.toLowerCase() == address.toLowerCase()&& model.isTemporary === true);
        console.log(`addressが${address}に一致するモデル:`, model);
        return model;
      } catch (error) {
        console.error(`addressが${address}に一致するモデルの検索に失敗しました:`, error);
        return undefined;
      }
    }

    const getMyTmpIdea = async() =>{
      try {
        const models = await DataStore.query(TmpIdea);
        console.log('全てのTmpIdea:', models);
        const model = models.filter(model => model.address.toLowerCase() == account.toLowerCase()&& model.isTemporary === true);
        setMyTmpIdea(model);
        console.log('MyTmpIdea',model)
      } catch (error) {
        console.error('Error in Fetching My Tmp Idea :', error);
      }
    }
  
  
  

  // DynamoDBから全てのモデルデータをフェッチする関数
  const fetchAllModels = async (Model,setModels) => {
    try {
      const models = await DataStore.query(Model);
      setModels(models);
    } catch (error) {
      console.error('モデルデータのフェッチに失敗しました:', error);
    }
  };


  // メソッド定義...
  const checkMetaMaskInstalled = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      alert('MetaMaskをインストールしてください！');
    }
  }

  const checkChainId = async () => {
    const { ethereum } = window;
    if (ethereum) {
      const chain = await ethereum.request({
        method: 'eth_chainId'
      });
      console.log(`chain: ${chain}`);

      if (chain != SEPOLIA_CHAIN_ID) {
        alert('Sepoliaに接続してください');
        setChainId(false)
      } else {
        setChainId(true)
      }
    }
  }

  const connectWallet = async () => {
    try {
      const { ethereum } = window;
      const accounts = await ethereum.request({
        method: 'eth_requestAccounts'
      });
      //console.log(`account: ${accounts[0]}`)
      setAccount(accounts[0])
      fetchUsername(accounts[0])//UsernameをDynamoDBから取得しusernameに格納する。

      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const IdeaFlowContract = new ethers.Contract(CONTRACT_ADDRESS, contractABI.abi, signer);

      const contractWithSigner = IdeaFlowContract.connect(signer);
      const ifowner = await contractWithSigner.ifOwner();

      setIsOwner(ifowner);
      
      //setMyTokens();
      setProvider(provider);
      setContractWithSigner(contractWithSigner);
      console.log('set contractWithSigner')
      setWalletConnected(true);///ここに追加。
      
      ethereum.on('accountsChanged', checkAccountChanged);
      ethereum.on('chainChanged', checkChainChanged);
    } catch (err) {
      console.log(err)
    }
  }

  const checkAccountChanged = () => {
    console.log('Metamask Account changed.')
    setAccount('');
    setUsername('');
    setIsOwner(false);
    setProvider(null);
    setContractWithSigner(null);
    setWalletConnected(false);
    setIsFirstRender(true);
  }

  const checkChainChanged = () => {
    console.log('Metamask Chain changed.');
    setChainId(false);
    setIsOwner(false);
    setProvider(null);
    setContractWithSigner(null);
    setWalletConnected(false);
    setIsFirstRender(true);
  }


  const getAllIdea = async () => {

    try {
      const [
        ideaIds,
        parentIds,
        ideaUris,
        proposers,
        totalEvalTokenss,
        contentHashes,
        flags,
        totalJobTokenss,
        totalScores,
        jobValues
      ] = await contractWithSigner.getAllIdeasArray();
  
      const allIdeas = ideaIds.map((ideaId, index) => {
        return [
          ideaId.toNumber(),//0
          parentIds[index].toNumber(),//1
          ideaUris[index],//2
          proposers[index],//3
          totalEvalTokenss[index].toNumber(),//4
          contentHashes[index],//5
          flags[index],//6
          totalJobTokenss[index].toNumber(),//7
          totalScores[index].toNumber(),//8
          jobValues[index].toNumber()//9
        ];
      });
  
      setAllIdea(allIdeas);
      console.log('getAllIdeas:', allIdeas)

    } catch (error) {
      alert(error);
      setAllIdea([])

    }
  }
  

  const signIn = async () => {
    try {
        const user = await Auth.signIn(email, password);
        console.log(user);
        setUser(user);
    } catch (error) {
        console.log('error signing in', error);
    }
};

  const checkIfOwner = async () => {
    const ifowner = await contractWithSigner.ifOwner();
    if (ifowner) {
      setIsOwner(true);
      console.log('you are the owner.')
    }
    else {
      setIsOwner(false);
    }
  }

  const isIdeaValid = async (idea) => {
    const isValid = idea[6];
    return isValid;
  }

  const ifMyIdea = async (ideaId) => {
    const ifmyidea = await contractWithSigner.ifMyIdea(ideaId);
    return ifmyidea;
  }


  const fetchUsername = async (address) => {
    //console.log('address', address)
    // Fetch usernames for each member
    const memberData = await DataStore.query(Members, c => c.address.eq(address.toLowerCase()));
    
    // memberData[0] が存在すればその username を、存在しなければ 'N/A' を設定します
    const username = memberData.length > 0 ? memberData[0].username : 'N/A';
    //console.log('fetchMember', username)
    setUsername(username);
  };

  const addressToUsername = async (address) => {
    const addressKomoji = address.toLowerCase()
    const memberData = await DataStore.query(Members, c => c.address.eq(address));
    const username = memberData.length > 0 ? memberData[0].username : 'N/A';
    return username;
  }

  const fetchMembers = async () => {
    if (!memberLoaded){
      const Cache = await DataStore.query(Members);
      setMembersDataCache(Cache)
      setMemberLoaded(true);
    }
  }


  const makeMemberAddressMapFromSC = async () => {
    const membersAddress = await contractWithSigner.getMembers();
    const memberAddressCache = await DataStore.query(Members);
    console.log('membersFromDatastore:',memberAddressCache);
    
    const mU2A = {};
    const mA2U = {}
    for (const address of membersAddress) {
      const member = memberAddressCache.find(m => m.address === address.toLowerCase());
      if (member && member.username) {
        mU2A[member.username] = address.toLowerCase();
        mA2U[address.toLowerCase()] = member.username;
      }
    }

    setMapUsernameToAddress(mU2A);
    setMapAddressToUsername(mA2U);
    console.log('mU2A',mU2A)
    console.log("mA2U",mA2U)

  }

  // const getAllJobs = async ()=>{
  //   setViewStatus(true);
  //   try {
  //     console.log('fetching AllJobs from blockchain')
  //     const [
  //       jobIds,jobURIs,jobbers,associatedIdeaIds,ifApproveds,contentHashes,jobValues,flags
  //     ] = await contractWithSigner.getAllJobsArray();

  //     const all_Jobs = jobIds.map((jobId, index) => {
  //       return [
  //         jobId,
  //         jobURIs[index],
  //         jobbers[index],
  //         associatedIdeaIds[index],
  //         ifApproveds[index],
  //         contentHashes[index],
  //         jobValues[index],
  //         flags[index]
  //       ];
  //     });

  //     console.log('allJobs',all_Jobs)

  //     setAllJobs(all_Jobs);
  //     setViewStatus(false);
  //   } catch(err){
  //     alert(err);
  //     setAllJobs([]);
  //     setViewStatus(false);
  //   }
  // }
  
  

  // const getJobsForIdea = async (ideaId_)=>{
  //   setViewStatus(true);
  //   try {
  //     console.log('fetching jobs from blockchain')
  //     const [
  //       jobIds,jobURIs,jobbers,associatedIdeaIds,ifApproveds,contentHashes,jobValues,flags
  //     ] = await contractWithSigner.getJobsForIdea(ideaId_);

  //     const associatedJobs = jobIds.map((jobId, index) => {
  //       return [
  //         jobId,
  //         jobURIs[index],
  //         jobbers[index],
  //         associatedIdeaIds[index],
  //         ifApproveds[index],
  //         contentHashes[index],
  //         jobValues[index],
  //         flags[index]
  //       ];
  //     });

  //     setJobsForIdea(associatedJobs);

  //     setViewStatus(false);
  //   } catch(err){
  //     alert(err);
  //     setJobsForIdea([]);
  //     setViewStatus(false);
  //   }
  // }

  // const getPendingJobIdsForIdeaOwner = async () => {
  //   const pJobs = await contractWithSigner.getPendingJobsForIdeaOwner();
  //   setPendingJobIds(pJobs);
  //   setNotificationsCount(pJobs.length)

  // }

  const getMyPortfolio = async () => {
      
    
    const [jobbedIdeas, jobTokens] = await contractWithSigner.getMyJobPortfolio();
    const [evalTokenIdeas,evalTokens] = await contractWithSigner.getMyEvalPortfolio();
    const [evaledIdeas,evalScores] = await contractWithSigner.getMyEvalScoreMap();
    // jobPortfolioを作成
    //evalPortfolioが必要


    const jobPortfolio = jobbedIdeas.map((ideaId, index) => ({
      ideaId: ideaId.toNumber(),
      tokens: jobTokens[index].toNumber()
    }));

    const evalPortfolio = evalTokenIdeas.map((ideaId, index) => ({
      ideaId: ideaId.toNumber(),
      tokens: evalTokens[index].toNumber()
    }));


    const evalScoreMap = evaledIdeas.map((ideaId, index) => ({
      ideaId: ideaId.toNumber(),
      tokens: evalScores[index].toNumber()
    }));


    setJobPortfolio(jobPortfolio);
    setEvalPortfolio(evalPortfolio);
    setScorePortfolio(evalScoreMap);
  
  }
  




  

  // 提供する値をまとめる
  const value = {
    updateModelByIdeaURI,
    getMyTmpIdea,
    filterModelsByIdeaURI,
    filterTmpModelsByAccount,
    allProfitLossMap,setAllProfitLossMap,
    allBusinessModelMap,setAllBusinessModelMap,
    allTmpIdea,setAllTmpIdea,
    myTmpIdea,setMyTmpIdea,
    fetchAllModels,
    chainId,
    account,
    provider,
    contractWithSigner,
    connectWallet,
    checkMetaMaskInstalled,
    checkChainId,
    signIn,
    setEmail,
    setPassword,
    user,
    email,
    password,
    isOwner,
    setIsOwner,
    isIdeaValid,
    checkIfOwner,
    ifMyIdea,
    fetchUsername,
    username,
    walletConnected,
    setWalletConnected,
    addressToUsername,
    key,
    setKey,
    allIdea,
    setAllIdea,
    getAllIdea,
    viewStatus,
    setViewStatus,
    isFirstRender, 
    setIsFirstRender,
    keyState,setKeyState,
    getMyPortfolio,
    jobPortfolio,
    evalPortfolio,
    scorePortfolio,
    fetchMembers,
    memberDataCache,
    mapUsernameToAddress,
    mapAddressToUsername,
    makeMemberAddressMapFromSC,
    amIJobberOfIdea,
    ifIScored

    // その他のステートやメソッド...
  }

  return (
    <MetaMaskContext.Provider value={value}>
      {children}
    </MetaMaskContext.Provider>
  );
}

export function useMetaMask() {
  const context = useContext(MetaMaskContext);
  if (!context) {
    throw new Error("useMetaMask must be used within a MetaMaskProvider")
  }
  return context;
}

