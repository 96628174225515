import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from "@chakra-ui/react";
import { MetaMaskProvider } from './services/MetaMaskContext';
import Header from './components/Header';
import MyPage from './pages/MyPage';
import MainPage from './pages/MainPage';
import OwnerPage from './pages/OwnerPage';
import MemberPage from './pages/MemberPage'
import IdeaTreePage from './pages/IdeaTreePage';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import Review from './components/reviewComponents/Review';

Amplify.configure(config);



function App() {
  return (
    <ChakraProvider>
      <MetaMaskProvider>
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/mypage" element={<MyPage />} />
        <Route path="/ownerpage" element={<OwnerPage />} />
        <Route path="/review" element={<Review />} />
        <Route path="/memberpage" element={<MemberPage />} />
        <Route path="/ideatreepage" element={<IdeaTreePage />} />

      </Routes>
    </Router>
    </MetaMaskProvider>
    </ChakraProvider>
  );
}


export default (App);


