import { useState, useEffect } from 'react';
import { Box, Button, Select, Text, Alert, AlertIcon, Image, Input, FormControl, FormLabel, Flex, VStack, Heading, HStack,
  Divider,Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Code }  from "@chakra-ui/react";
import  {Amplify, Storage,DataStore } from 'aws-amplify';
import {ethers} from "ethers";
import { FaCheckDouble } from 'react-icons/fa';
import { ProfitLossMap,BusinessModelMap } from '../models';
import { useMetaMask } from '../services/MetaMaskContext';


const ReviewEvaluation = ({ideaURI,thisAccount}) => {

    const [jsonData,setJsonData] = useState()
    const [evalRemarks,setEvalRemarks] =useState('')
    const {mapAddressToUsername} = useMetaMask()
    const [isLoaded,setIsLoaded] = useState(false)

    
    const handleLoad = async () => {
        try {
          const filename = `ideas/${ideaURI}/evaluation/${thisAccount.toLowerCase()}.json`
          const fileURLInInterest = await Storage.get(filename)

          fetch(fileURLInInterest)
            .then(response => response.json())
            .then(data => {
              setJsonData(data);
              const _remarks = data.remarks;
              setEvalRemarks(_remarks)
            })
            setIsLoaded(true)
        } catch (error) {
          console.error('Error fetching files', error);
        }
    };
    useEffect(() => {
      if(!isLoaded){
        handleLoad();
      }
    }, []);


    return (
      <>                    
      <Text>{evalRemarks}</Text>
    </>
      );
      
}
export default ReviewEvaluation;
