import React from 'react';
import {
  Flex,
  HStack,
  Box,
  Spacer,
  Image,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import HumbergerMenu from './HumbergerMenu'; // ハンバーガーメニューコンポーネントのインポート
import IdeaFlowBankIconImage from "../assets/ideaflowbank_icon_1024x1024_1x.png"
import { Link as RouterLink } from "react-router-dom";
import { useMetaMask } from '../services/MetaMaskContext';
import EditIdeaButton from './EditIdeaButton';

function Header() {
  const {walletConnected,myTmpIdea} = useMetaMask();
  function extractText(str) {
    const match = str.match(/_(.*?)_/);
    if (match) {
        return match[1];
    }
    return null;
}
  return (
    <Flex
     bg="gray.100"
      as="header"
      w="100%"
      p={3}
      boxShadow="md"
      alignItems="center"
      justifyContent="space-between"
    >
     {walletConnected&&
     <HStack>
      <HumbergerMenu />


            
      {/* サイトアイコン */}
      <Box ml={6}>
        <RouterLink to="/">
          <Image src={IdeaFlowBankIconImage} 
            alt="IdeaFlowBankIcon" 
            w="50px"   // 幅を300pxに固定
            h="50px"   // 高さを200pxに固定
            objectFit="cover"   // 上下をトリミング
            cursor="pointer"    // ホバー時にカーソルをポインタに変更
          />
        </RouterLink>
      </Box>
      {myTmpIdea&&myTmpIdea.length > 0 && (
            <Menu>
              <MenuButton as={Badge} colorScheme="red" p={2} cursor="pointer">
                編集中のアイデアが{myTmpIdea.length}個 <ChevronDownIcon />
              </MenuButton>
              <MenuList>
                {myTmpIdea.map((idea, index) => (
                  <MenuItem key={index} onClick={() => console.log(`${idea.ideaURI}が選択されました`)}>
                    {extractText(idea.ideaURI)}
                  <EditIdeaButton tmpIdea={idea} />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}

      
      <Spacer />
      
      </HStack>
     }

    </Flex>
  );
}

export default Header;
