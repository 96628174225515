import { useState, useEffect } from 'react';
import { Center,Tooltip,Box, VStack, Button, HStack, Heading, Text, useDisclosure, Modal,
    ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper,
    IconButton,Alert, AlertIcon
  } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { useMetaMask } from '../services/MetaMaskContext';
import WaitTransaction from '../services/WaitTransaction';

function InvalidateIdeaButton({ ideaId,onClosePrev }) {///前のmodalのonCloseを引き継ぐ
  //console.log("ideaId",ideaId)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { contractWithSigner,setKey,setKeyState} = useMetaMask();
    const [error, setError] = useState(null); // 追加
    const [transactionStatus, setTransactionStatus] = useState(null);//追加


    const handleInvalidate = async () => {
      try {
        setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット
        const tx = await contractWithSigner.invalidateIdea(ideaId);
        const receipt = await tx.wait();
        setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット

      } catch(error) {
        console.error(error);
        setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
        

      }
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(1500); // 1500ミリ秒（1.5秒）待つ
      
      setTransactionStatus(null); // 閉じたいのでnullにリセット
      onClose(); // 追加: モーダルを閉じる
      onClosePrev();//追加 IdeaBoxのmodalを閉じる
      setKey(prevKey => prevKey + 1);
      setKeyState(true);
    }

    
    return (
      <>{transactionStatus && (
      <WaitTransaction transactionStatus={transactionStatus} />
      )} {/* 追加 */}

      {error && 
        <Box role="alert">
          <Alert status="error">
            <AlertIcon />
            {error}
          </Alert>
        </Box>
      } {/* 追加 */}

          <Center>
            <Tooltip label="アイデアを消去">
              <Button 
                colorScheme="yellow" 
                onClick={onOpen} 
                borderRadius="full"
                _hover={{
                  backgroundColor: "teal.400", // 2. ボタンの色が変わる
                  boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
                }}
              >
                <FaTrash size="1.5em" /> 
              </Button>
            </Tooltip>
          </Center>

          <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                          <ModalHeader marginLeft="20px">
                              本当にこのアイデアを無効化しますか? 
                          </ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                          </ModalBody>
                          <ModalFooter justifyContent="center">
                              <Button colorScheme="teal" onClick={handleInvalidate}>無効化する</Button>
                          </ModalFooter>
   
              </ModalContent>
          </Modal>
      </>
  );
  
  
  }

  export default InvalidateIdeaButton;
  