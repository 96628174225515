import { Chart, ArcElement,PieController, CategoryScale, Title, Tooltip, Legend } from 'chart.js';
import { useEffect, useRef } from 'react';
Chart.register(PieController,ArcElement, CategoryScale, Title, Tooltip, Legend);

export const usePieChart = (canvasRef, data) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(canvasRef.current, {
      type: 'pie',
      data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      },
    });
  }, [data]);

  return chartRef;
};
