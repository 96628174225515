import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Editor, EditorState, RichUtils, convertToRaw, convertFromRaw,Modifier } from 'draft-js';
import  {Storage } from 'aws-amplify';
import {stateToHTML} from 'draft-js-export-html';
import { useMetaMask } from '../services/MetaMaskContext';
import { useDropzone } from 'react-dropzone';
import { HStack,Spacer,VStack,InputGroup,InputRightAddon,Box, Button,Select, Text, Alert, AlertIcon, Image, Input,FormControl, FormLabel, Flex,Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
Textarea}  from "@chakra-ui/react";
import { sha256 } from 'js-sha256';
import FileDropzone from './FIleDropZone';
import WaitTransaction from '../services/WaitTransaction';
import ProfitLossInput from './ProfitLossInput';
import BusinessModelInput from './BusinessModelInput';
import { DataStore } from '@aws-amplify/datastore';
import { ProfitLossMap,BusinessModelMap, TmpIdea } from '../models';


export default function EditIdea ({onClose,tmpIdea})  {
  const [uploaded, setUploaded] = useState(false);
  const [fileName,setFileName] = useState("")
  const [imageFile, setImageFile] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [error, setError] = useState(null); // 追加
  const [transactionStatus, setTransactionStatus] = useState(null);//追加
  const [entries, setEntries] = useState([{ username: '', address: '',proportion: 10}]);
  const [remarks,setRemarks] = useState('')
  const [parentId,setParentId] = useState()
  const [profitLossInput, setProfitLossInput] = useState({})
//   ({
//     'revenue': 0,
//     'cost': 0,
//     'grossMargin': 0,
//     'expense': 0,
//     'salesProfit': 0
//   })
  const [businessModelInput, setBusinessModelInput] = useState({})
  const [parentProfitLossInput, setParentProfitLossInput] = useState({
    'revenue': 0,
    'cost': 0,
    'grossMargin': 0,
    'expense': 0,
    'salesProfit': 0
  })
  const [parentBusinessModelInput, setParentBusinessModelInput] = useState({
    'customerSegment':{},
    'valueProposition':{},
    'channel':{},
    'relationshipWithCustomers':{},
    'incomeFlow':{},
    'resources':{},
    'activities':{},
    'partners':{},
    'costStructure':{}
    }
  )

  const [bmi_model,setBmi_model] = useState()
  const [pl_model,setPl_model] = useState()
  

  const {contractWithSigner,username,account,setKey,setKeyState,mapUsernameToAddress,mapAddressToUsername,filterModelsByIdeaURI,allBusinessModelMap,allProfitLossMap,updateModelByIdeaURI,allTmpIdea} = useMetaMask();///DynamoDBからusernameを取得している。これをideaURIに転用。
  const [isConfirmModalOpen,setConfirmModalOpen] = useState(false)
  const onConfirmModalClose = () =>{setConfirmModalOpen(false)}
  const onConfirmModalOpen = () => {setConfirmModalOpen(true)}

  const [isTmpSaveModalOpen,setTmpSaveModalOpen] = useState(false)
  const onTmpSaveModalClose = () =>{setTmpSaveModalOpen(false)}
  const onTmpSaveModalOpen = () => {setTmpSaveModalOpen(true)}


  const [isModelFetched, setModelFetched] = useState(false)

  useEffect(() => {

    const loadParentIdea = async()=>{
        setParentBusinessModelInput(filterModelsByIdeaURI(allBusinessModelMap,tmpIdea.parentIdeaURI))
        setParentProfitLossInput(filterModelsByIdeaURI(allProfitLossMap,tmpIdea.parentIdeaURI))
    }

    const loadTmpIdea = async () => {
        try {
          const tmpFileName = tmpIdea.ideaURI

          console.log('fetching parent and temp Models...')
          
          const pli = filterModelsByIdeaURI(allProfitLossMap,tmpIdea.ideaURI)
          setProfitLossInput(pli)
          setPl_model(pli)
          const bmi = filterModelsByIdeaURI(allBusinessModelMap,tmpIdea.ideaURI)
          setBusinessModelInput(bmi)
          setBmi_model(bmi)
          setFileName(extractText(tmpFileName))
          const filesInDirectory = await Storage.list(`ideas/tmp/${tmpFileName}`);
          for (const file of filesInDirectory.results) {
            const fileUrl = await Storage.get(file.key);  // Get file URL
            if (file.key == `ideas/tmp/${tmpFileName}/${tmpFileName}.json`) {
              // fetch the JSON file and save it to state
              fetch(fileUrl)
                .then(response => response.json())
                .then(data => {
                    console.log('S3',file.key)
                    setParentId(data.parentId)
                    setRemarks(JSON.parse(data.combinedContent).remarks)
                    const _proportionArr = data.proportion
                    const _jobbersArr = data.jobbers
                    const tmpEntries = _jobbersArr.map((address, index) => {
                        const username = mapAddressToUsername[address]; // アドレスからユーザー名を取得
                        const proportion = _proportionArr[index]; // 同じインデックスの割合を取得
                        return {
                          username: username || '', // マッピングにユーザー名がない場合は空文字を設定
                          address: address || '', // 提供されたアドレス、または空文字
                          proportion: proportion || 10 // 提供された割合、または0
                        };
                      });
                    setEntries(tmpEntries)
                    
                });
            } 
          }
        } catch (error) {
          console.error('Error fetching files', error);
        }
    };


    if (!isModelFetched) {
        if (parentId>0){
            loadParentIdea()
        }
        loadTmpIdea()
        setModelFetched(true)
    }
  }, []);

    function extractText(str) {
        const match = str.match(/_(.*?)_/);
        if (match) {
            return match[1];
        }
        return null;
    }



  const handleMemberSelect = (index, username) => {
    let address = "";
    if (username !== "") {
      address = mapUsernameToAddress[username];
    }
  
    // 既に同じアドレスが選択されているかチェック
    const isDuplicateAddress = entries.some((entry, i) => i !== index && entry.address === address);
    if (isDuplicateAddress) {
      alert('このアドレスは既に選択されています。');
      return; // ここで処理を中断
    }
  
    updateEntry(index, 'username', username);
    updateEntry(index, 'address', address);
  };

  const addEntry = () => {
    const lastEntry = entries[entries.length - 1];
    if (lastEntry.address.trim() !== '') {
      setEntries([...entries, { username: '', address: '', proportion: 10 }]);
    }
    console.log(entries);
  };

  const updateEntry = (index, key, value) => {
    const updatedEntries = [...entries];
    updatedEntries[index][key] = value;
    if (updatedEntries.length > 1 && updatedEntries[index].username.trim() === '' &&
        updatedEntries[index].address.trim() === '' && updatedEntries[index].proportion === 10) {
      updatedEntries.splice(index, 1);
    }
    setEntries(updatedEntries);
  };





  const onDrop = useCallback(acceptedFiles => {
    // Instead of uploading the files, just save them to state
    setImageFile(acceptedFiles[0]);
    setPreviewImage(URL.createObjectURL(acceptedFiles[0]));
    console.log('acceptedFiles',acceptedFiles)
    console.log(acceptedFiles[0])
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/*,video/*'})

  const reshapeBusinessModel = (businessModelInput) => {
    const res = Object.keys(businessModelInput).reduce((acc, key) => {
        // createdAt、updatedAt、_lastChangedAt、_version、_deleted などのフィールドを除外
        const excludeFields = ['createdAt', 'updatedAt', '_lastChangedAt', '_version', '_deleted', 'id','ideaURI'];
      
        // 除外リストに含まれない、かつ null でないフィールドのみ JSON.stringify を適用
        if (!excludeFields.includes(key) && businessModelInput[key] !== null) {
          acc[key] = JSON.stringify(businessModelInput[key]);
        } else {
          // 除外リストに含まれるフィールド、または null の場合はそのまま値を代入
          acc[key] = businessModelInput[key];
        }
        return acc;
      }, {});

      return res;

  }
  
  const handleTmpSave = async () => {
    const tmpFileName = tmpIdea.ideaURI
    const jsonFileName = `ideas/tmp/${tmpFileName}/${tmpFileName}.json`;
    const addressArray = entries.map(entry => entry.address);
    const proportionArray = entries.map(entry => entry.proportion);
    
    
    const businessModelToSave = reshapeBusinessModel(businessModelInput)

    const remarksContent = remarks;//string型
    const combinedObject = {
      title:fileName,
      businessModel: businessModelToSave,
      profitAndLoss: profitLossInput,
      remarks: remarksContent
    };
    const combinedJsonString = JSON.stringify(combinedObject);
    const dataToSave = {
      title: fileName,
      user: username,
      address: account,
      combinedContent:combinedJsonString,
      parentId: parentId,
      jobbers:addressArray,
      proportion:proportionArray
      };

    const rawAllContentStr = JSON.stringify(dataToSave);

      
    try{
      setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット

    //   const savedProfitLossMapObject = await DataStore.save(new ProfitLossMap(profitLossInput))
    //   const savedBusinessModelMapMapObject = await DataStore.save(new BusinessModelMap(businessModelToSave))
    //   const updatedModel_pl = updateModelByIdeaURI(ProfitLossMap,tmpIdea.ideaURI,profitLossInput)
    //   const updatedModel_bm = updateModelByIdeaURI(BusinessModelMap,tmpIdea.ideaURI,businessModelToSave)
      await DataStore.save(
        ProfitLossMap.copyOf(pl_model, updated => {
          Object.assign(updated, profitLossInput);
        })
      );

      await DataStore.save(
        BusinessModelMap.copyOf(bmi_model, updated => {
          Object.assign(updated, businessModelToSave);
        })
      );
      //const updatedModel_tm = updateModelByIdeaURI(TmpIdea,tmpIdea.ideaURI,)
      await Storage.put(jsonFileName, rawAllContentStr);
      //await Storage.put(newImageFileName, imageFile);
      
     

      setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      setKey(prevKey => prevKey + 1);
      setKeyState(true);
    } catch(error) {
      console.error('Error uploading file', error)
      setError(error.message); // 追加
      setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      return;
    }

  }

  const handleSave = async () => {
    // Imageファイルチェック
    if (!imageFile || !imageFile.name) {
      alert('Please drop a file before saving.');
      return;
    }

    // Get current datetime
    // const date = new Date();
    // const datetimeString = `${date.getFullYear()}${date.getMonth()+1}${date.getDate()}_${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
     
    //     // Create the file name using the user's name and the current datetime
    //     //jobsフォルダに入れることにしてみる
    const tmpFileName = tmpIdea.ideaURI
    // const newFileName = `${username}_${fileName}_${datetimeString}`;
    const jsonFileName = `ideas/${tmpFileName}/${tmpFileName}.json`;

    const newImageFileName = `ideas/${tmpFileName}/${tmpFileName}${imageFile.name.slice(imageFile.name.lastIndexOf('.'))}`;


    const addressArray = entries.map(entry => entry.address);
    const proportionArray = entries.map(entry => entry.proportion);

    // const businessModelToSave = Object.keys(businessModelInput).reduce((acc, key) => {
    //   try{
    //     acc[key] = JSON.stringify(businessModelInput[key]);
    //   } catch (e){
    //     acc[key] = businessModelInput[key]
    //   }
    //   return acc;
    // }, {});
    const businessModelToSave = reshapeBusinessModel(businessModelInput)




    //const contentState = editorState.getCurrentContent();
    //const htmlContent = stateToHTML(contentState);

    const remarksContent = remarks;//string型

    // 新しいJSONオブジェクトを作成
    const combinedObject = {
      title:fileName,
      businessModel: businessModelToSave,
      profitAndLoss: profitLossInput,
      remarks: remarksContent
    };
    const combinedJsonString = JSON.stringify(combinedObject);

    console.log('combinedJsonString',combinedJsonString)
    // The HTML content can now be saved as a string
    //const htmlContentStr = JSON.stringify({content: htmlContent});
    //console.log('htmlContentStr',htmlContentStr)
    // Create a hash of the rawContentStr
    const contentHash = sha256(combinedJsonString);
    //const contentHash = sha256(htmlContentStr);
    const bytes32Hash = "0x" + contentHash;

    setError(null); // 追加

    // S3とBlockchainへの保存
    try {
      setTransactionStatus('pending'); // トランザクション開始前に状態を'pending'にセット
      const tx_kai = await contractWithSigner.addIdea(tmpFileName,parentId,bytes32Hash,addressArray)
      const receipt = await tx_kai.wait(); // トランザクションのレシートを取得 

      console.log('Blockchain uploaded successfully');

      const dataToSave = {
        title: fileName,
        user: username,
        address: account,
        hashTags: bytes32Hash,
        //content: rawContentStr,
        combinedContent:combinedJsonString,
        //ideaId: newIdeaId,///ブロックチェーンからIdeaIDを取得して入れる。
        parentId: parentId,
        jobbers:addressArray,
        proportion:proportionArray
        };

      const rawAllContentStr = JSON.stringify(dataToSave);
      
      //upload to S3
    //   const savedProfitLossMapObject = await DataStore.save(new ProfitLossMap(profitLossInput))
    //   const savedBusinessModelMapMapObject = await DataStore.save(new BusinessModelMap(businessModelToSave))
    //   const updatedModel_pl = await updateModelByIdeaURI(ProfitLossMap,tmpIdea.ideaURI,profitLossInput)
    //   const updatedModel_bm = await updateModelByIdeaURI(BusinessModelMap,tmpIdea.ideaURI,businessModelToSave)
      //await updateIsTemporary(tmpIdea,TmpIdea, false)
      await DataStore.save(
        ProfitLossMap.copyOf(pl_model, updated => {
          Object.assign(updated, profitLossInput);
        })
      );

      await DataStore.save(
        BusinessModelMap.copyOf(bmi_model, updated => {
          Object.assign(updated, businessModelToSave);
        })
      );

      await DataStore.save(
        TmpIdea.copyOf(tmpIdea, updated => {
          updated.isTemporary = false;
        })
      );
    //   console.log('DynamoDB, ProfitLoss,',updatedModel_pl)
    //   console.log('DynamoDB, BusinessModel',updatedModel_bm)
      console.log("DynamoDB data uploaded successfully.")
      await Storage.put(jsonFileName, rawAllContentStr);
      await Storage.put(newImageFileName, imageFile);
      console.log('S3 File uploaded successfully');
      setTransactionStatus('success'); // トランザクション成功後に状態を'success'にセット

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
    
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose(); // 追加: モーダルを閉じる
      setKey(prevKey => prevKey + 1);
      setKeyState(true);

    } catch (error){
      console.error('Error uploading file', error)
      setError(error.message); // 追加
      setTransactionStatus('error'); // トランザクション失敗時に状態を'error'にセット
      
      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
      await delay(2000); // 2000ミリ秒待つ
      setTransactionStatus(null); // 閉じたいのでnullにセット
      onClose();
      return;

    } 

  };


    // Function to render the input values
    



    const renderInputValues_BM = () => {
      return (
        
        Object.entries(businessModelInput).map(([key, values]) => (
        <Box key={key} mb={4}>
          <Text >{key}</Text>
          {Object.entries(values).map(([number, value]) => (
            <Text key={number}>{`${number}: ${value}`}</Text>
          ))}
        </Box>
      ))
      );
    };

    const renderInputValues_PL = () => {
      return (
        

        Object.entries(profitLossInput).map(([key,value]) => (
          <HStack spacing={5}>
            <Box width="8rem">
            {key}:
            </Box>
            <Text>{value}</Text>
          </HStack>
        ))
        
      )
    }

    const checkDB = () =>{
        const businessModelToSave = Object.keys(businessModelInput).reduce((acc, key) => {
            // createdAt、updatedAt、_lastChangedAt、_version、_deleted などのフィールドを除外
            const excludeFields = ['createdAt', 'updatedAt', '_lastChangedAt', '_version', '_deleted', 'id','ideaURI'];
          
            // 除外リストに含まれない、かつ null でないフィールドのみ JSON.stringify を適用
            if (!excludeFields.includes(key) && businessModelInput[key] !== null) {
              acc[key] = JSON.stringify(businessModelInput[key]);
            } else {
              // 除外リストに含まれるフィールド、または null の場合はそのまま値を代入
              acc[key] = businessModelInput[key];
            }
            return acc;
          }, {});
          
        

        // tmpIdea.isTemporary = false
        console.log('businessModelToSave',BusinessModelMap.copyOf(bmi_model, updated => {
            Object.assign(updated, businessModelToSave);
          }))
        console.log('profitandloss',ProfitLossMap.copyOf(pl_model, updated => {
            Object.assign(updated, profitLossInput);
          }))
        console.log('tmpIdea',tmpIdea)

    }



  return (
    <>
    <Box className="App" pt="30px" pb="30px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {transactionStatus && (
  <WaitTransaction transactionStatus={transactionStatus} />
)} {/* 追加 */}


    <FormControl w="500px" display="flex" flexDirection="row" alignItems="center" mb="20px">
    <Text fontWeight="bold"  textAlign="center" fontSize="xl" >{fileName}</Text>
    </FormControl>
    <Box w="400px" h="auto" marginTop="10px">
      <FileDropzone onDrop={onDrop} isDragActive={isDragActive} uploaded={uploaded} previewImage={previewImage}  />
    </Box>
    <VStack margin="auto" width="100%" maxWidth="1080" style={{ display: 'flex', flexDirection: 'column', gap: '5rem' }}>
      <BusinessModelInput businessModelInput={businessModelInput} setBusinessModelInput={setBusinessModelInput} parentBusinessModel={parentBusinessModelInput}/>
      <ProfitLossInput profitLossInput={profitLossInput} setProfitLossInput={setProfitLossInput} parentProfitLoss={parentProfitLossInput}/>
      {/* <RemarksEditor/> */}
      <Textarea placeholder='アイデアの概要を入力' size="md" height="200px" width='lg' mt='100px' value={remarks} onChange={e=>setRemarks(e.target.value)} />
    </VStack>

    <VStack spacing={4} width="600px" margin="auto" mt={10}>
    {entries.map((entry, index) => (
    <HStack key={index} width="100%" spacing={5}>
        <FormControl id={`address-${index}`} flex={1}>
            <FormLabel>Address {index + 1}</FormLabel>
            <Select 
                value={entry.username}
                onChange={(e) => handleMemberSelect(index, e.target.value)}
            >
                <option value="">-- Jobberをプルダウンから選択 --</option>
                {Object.keys(mapUsernameToAddress).map(username => (
                    <option key={username} value={username}>
                        {username}
                    </option>
                ))}
            </Select>
        </FormControl>
        <FormControl id={`proportion-${index}`} flex={1}>
            <FormLabel>Proportion</FormLabel>
            <Select 
              value={entry.proportion}
              onChange={(e) => updateEntry(index, 'proportion', parseInt(e.target.value))}
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(value => (
                <option key={value} value={value}>
                  {value}%
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
      ))}
      <HStack>
      <Button onClick={addEntry} colorScheme="blue" size="md">
        他のJobberを追加
      </Button>

      </HStack>
    </VStack>

    <Modal isOpen={isConfirmModalOpen} onClose={onConfirmModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>入力内容の確認</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <HStack>
            <Text fontWeight="bold">Title:</Text><Text> {fileName}</Text>
            </HStack>
            <Text fontWeight="bold">BusinessModel</Text>
            {renderInputValues_BM()}
            <Text fontWeight="bold">Profit&Loss</Text>
            {renderInputValues_PL()} */}
            {/* ここに他のvalueの確認を入れる */}
            {/* <Text fontWeight="bold">概要</Text>
            <Text>{remarks}</Text> */}
            <VStack>
            <Text fontWeight="bold">評価をお願いすると、</Text>
            <Text fontWeight="bold">アイデアがブロックチェーンに書き込まれます。</Text>
            <Text fontWeight="bold">編集・変更できなくなりますが、OKですか？</Text>
            </VStack>

          </ModalBody>
                 
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onConfirmModalClose}>
              閉じる
            </Button>
            <Button variant="ghost" onClick={() => {
              handleSave();
              onConfirmModalClose();
            }}>
              送信
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    <Button 
      marginTop="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onConfirmModalOpen}
      >確定
      </Button>


      <Modal isOpen={isTmpSaveModalOpen} onClose={onTmpSaveModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>一時保存します。</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
            <Text>ブロックチェーンには書き込まれません。</Text>
            <Text>サムネイルは保存されません。</Text>
            </VStack>
          </ModalBody>
                 
          <ModalFooter>

            <Button colorScheme="blue" mr={3}  onClick={() => {
              handleTmpSave();
              onTmpSaveModalClose();
            }}>
              一時保存
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    <Button 
      marginTop="50px" 
      color="white" 
      bg="teal.500" 
      _hover={{bg:"teal.300" }}
      onClick={onTmpSaveModalOpen}
      >一時保存
      </Button>

                
    </Box>
    </>
  );
};
