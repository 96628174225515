import React, { useState, useEffect } from 'react';
import {
    Text,
    Tooltip,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Box,
    Stat,
    StatLabel,
    StatNumber,
    StatGroup,
    Divider,
    useColorModeValue,
    useDisclosure
  } from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext'; // あなたのコンテキストへのパスに基づいて調整してください
import { FaRegListAlt } from 'react-icons/fa';
import ReviewEvaluation from './ReviewEvaluation';

export default function ShowScoreButton({ idea }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [scoreData, setScoreData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [clickedAccount, setClickedAccount] = useState("")
  const [ifIScored, SetIfIScored] = useState(false)

  const { contractWithSigner,mapAddressToUsername,account,amIJobberOfIdea} = useMetaMask();
  const [amIJobber,setAmIJobber] = useState(false)
    // 色モードフック
  const tableBg = useColorModeValue("gray.50", "gray.700");

  // スコアデータを取得する関数
  const fetchScoreData = async () => {
    try {
      setAmIJobber(await amIJobberOfIdea(idea[0]))
      
      const [membersWithScore, evaledScores] = await contractWithSigner.getMemberScoreMap(idea[0]);
      const evalScores = evaledScores.map(score => score.toNumber());
      const totalScore = evalScores.reduce((acc, score) => acc + score, 0);
      const averageScore = evalScores.length > 0 ? (totalScore / evalScores.length).toFixed(2) : 0;

      SetIfIScored(membersWithScore.some(addr => addr.toLowerCase() === account.toLowerCase()));
      
      // 取得したスコアデータをstateにセット
      setScoreData({
        membersWithScore,
        evalScores,
        averageScore,
        evaluatorCount: evalScores.length,
      });
    } catch (error) {
      console.error("Failed to fetch score data:", error);
      setScoreData(null);
    }
  };




  // 第一モーダルを開くボタンのクリックハンドラ
  const handleOpenModal = async () => {
    await fetchScoreData();
    setModalOpen(true);
  };

  const ScoreTable = () =>{
    return(<>
      {scoreData ? (
        <Box>
          <StatGroup>
            <Stat>
              <StatLabel>Average Score</StatLabel>
              <StatNumber>{scoreData.averageScore}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Number of Evaluators</StatLabel>
              <StatNumber>{scoreData.evaluatorCount}</StatNumber>
            </Stat>
          </StatGroup>

          <Divider my={4} />

          <Table variant="simple" bg={tableBg} borderRadius="md" boxShadow="base">
            <Thead>
              <Tr>
                <Th>Member</Th>
                <Th isNumeric>Score</Th>
              </Tr>
            </Thead>
            <Tbody>
              {scoreData.membersWithScore.map((member, index) => (
                <Tr key={member}>
                  <Td>{mapAddressToUsername[member.toLowerCase()] || "Unknown"}</Td>
                  <Td isNumeric>{scoreData.evalScores[index]}</Td>
                  <Td>
                    <Button 
                      colorScheme="teal" 
                      onClick={() => handleMemberClick(member)} 
                      borderRadius="full"
                      _hover={{
                        backgroundColor: "teal.400",
                        boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      詳細
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          {clickedAccount && <EvaluationRemarksModal />}
   
        </Box>
        
      ) : (
        <Text>誰も評価していません</Text>
      )}
    </>
    )
    
  }


  const handleMemberClick = (member) => {
    
    setClickedAccount(member.toLowerCase());
    onOpen();
  };

  function EvaluationRemarksModal() {
    return(
      <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>評価概要</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* ここに選択されたメンバーの詳細を表示するコンポーネント */}
          <ReviewEvaluation ideaURI={idea[2]} thisAccount={clickedAccount}/>
          {/* 今回は単純なテキストで表示 */}
        </ModalBody>
      </ModalContent>
    </Modal>
    )
  }

  return (
    <div>
      <Tooltip label="Show Scores" placement="right-start">
        <Button
          colorScheme="teal"
          onClick={handleOpenModal}
          borderRadius="full"
          _hover={{
            backgroundColor: "teal.400",
            boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.1)",
          }}
        >
          <FaRegListAlt size="1.5em" />
        </Button>
      </Tooltip>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
          <ModalContent>
          {idea[9] > 0 ? (
            <>
              <ModalHeader>Scores for Idea ID: {idea[0]}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ScoreTable />
                <Text mt="4">評価確定済</Text>
              </ModalBody>
            </>
          ) : amIJobber ? (
            <>
              <ModalHeader>Scores for Idea ID: {idea[0]}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ScoreTable />
                <Text mt="4">評価未確定</Text>
              </ModalBody>
            </>
          ) : ifIScored ? (
            <>
              <ModalHeader>Scores for Idea ID: {idea[0]}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <ScoreTable />
                <Text mt="4">評価未確定</Text>
              </ModalBody>
            </>
          ) : (
            <>
              <ModalHeader>Score Information</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>評価してから公開されます。</Text>
              </ModalBody>
            </>
          )}
        </ModalContent>

      </Modal>

    </div>
  );
}


