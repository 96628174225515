import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import {Text,Tooltip, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext'; // あなたのコンテキストへのパスに基づいて調整してください
import {FaChartPie} from "react-icons/fa6"


export default function ShowDistributionButton({ ideaId }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [distributionData, setDistributionData] = useState(null);
  const { contractWithSigner,memberDataCache,mapAddressToUsername} = useMetaMask();

  const generateRandomColor = () => {
    const randomByte = () => Math.floor(Math.random() * 256);
    return `rgba(${randomByte()},${randomByte()},${randomByte()},0.7)`;
  };

  const getAddressUsername = (address) => {
    const member = memberDataCache.find(m => m.address === address.toLowerCase());
    return member ? member.username : address;
  };

  const fetchDistributionJEData = async () => {
    try {
      const [addresses, jobTokenCountsBigNumbers,evalTokenCountsBigNumbers] = await contractWithSigner.getDistributionJEFromIdeaToRoot(ideaId);
        
      const jobTokenCounts = jobTokenCountsBigNumbers.map(tc => tc.toNumber());
      const evalTokenCounts = evalTokenCountsBigNumbers.map(tc => tc.toNumber());
      const totalTokenCounts = jobTokenCounts.map((jtc,index)=>jtc+evalTokenCounts[index])
      

      const members = addresses.map((address) => mapAddressToUsername[address.toLowerCase()]);
      const colors = members.map(() => generateRandomColor());

      const combinedData = [];
      const totalData = [];

        for (let i = 0; i < jobTokenCounts.length; i++) {
            combinedData.push(jobTokenCounts[i]);    // jobToken の値を追加
            combinedData.push(evalTokenCounts[i]);   // evalToken の値を追加
            totalData.push(jobTokenCounts[i]+evalTokenCounts[i])
        }

        const combinedLabels = [];
        const combinedColors = [];

        for (let i = 0; i < members.length; i++) {
            combinedLabels.push(`${members[i]} (Job)`);
            combinedLabels.push(`${members[i]} (Eval)`);

            const color = generateRandomColor();  // 各メンバーに対して1色を生成
            combinedColors.push(color);           // jobToken の色
            combinedColors.push(color);           // evalToken の色 (jobToken と同じ色)
        }


        const options = {
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        const index = tooltipItem.index;
                        const labelName = data.labels[index]; // 現在のラベル名を取得
                        const currentValue = data.datasets[tooltipItem.datasetIndex].data[index];
                        const totalValue = data.datasets[tooltipItem.datasetIndex].data.reduce((acc, cur) => acc + cur, 0);
                        const percentage = ((currentValue / totalValue) * 100).toFixed(2); // 割合を計算
                        
                        // ラベル名に"Job"または"Eval"が含まれているかどうかをチェックして、適切な値を返します
                        if (labelName.includes("Job")) {
                            return `Job: ${currentValue} (${percentage}%)`;
                        } else if (labelName.includes("Eval")) {
                            return `Eval: ${currentValue} (${percentage}%)`;
                        }
                    }
                }
            },
            legend: {
                labels: {
                    filter: function(legendItem, chartData) {
                        // 各メンバー名の"Job"だけがLegendに表示されるようにする
                        return combinedLabels[legendItem.index].includes("(Job)");
                    },
                    generateLabels: function(chart) {
                        // 既存のラベルを取得
                        const originalLabels = chart.defaults.global.legend.labels.generateLabels(chart);
                        // 各ラベルから"(Job)"を削除
                        return originalLabels.map(label => {
                            return {
                                ...label,
                                text: label.text.replace("(Job)", "")
                            };
                        });
                    }
                }
            }
        };
        
          
          // その後、このオプションをチャートのオプションとして指定してください。
          

        const data = {
            labels:combinedLabels,
            options:options,
            datasets: [
                {
                data: combinedData,
                backgroundColor: combinedColors,
                hoverBackgroundColor: combinedColors
            }]

        };
    setDistributionData(data)



    } catch (error) {
      console.error("Failed to fetch distribution data:", error);

    }
  };


  const handleOpenModal = async () => {
    await fetchDistributionJEData();
    setModalOpen(true);
  };

  return (
    <div>


      <Tooltip label="Distribution" placement="right-start">
        <Button 
          colorScheme="teal" 
          onClick={handleOpenModal} 
          borderRadius="full"
          _hover={{
            backgroundColor: "teal.400", // 2. ボタンの色が変わる
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)", // 3. シャドーができる
          }}
        >
          <FaChartPie size="1.5em" /> 
        </Button>
        </Tooltip>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Distribution for Idea ID: {ideaId} A/O Upper</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {distributionData && <Pie data={distributionData} />}

          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

