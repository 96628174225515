import IdeaGroupsTree from '../components/IdeaGroupsTree';
import React from 'react';
import {useEffect,useState } from "react";
import { Button, Flex, Box, Text,Heading,Input,Image} from "@chakra-ui/react";
import { useMetaMask } from '../services/MetaMaskContext';
import { useLocation, useNavigate } from "react-router-dom";
import IdeaFlowBankIconImage from "../assets/ideaflowbank_icon_1024x1024_1x.png"

function IdeaTreePage() {
  const location = useLocation();
  const navigate = useNavigate();
  //MetaMaskContext.jsより、useState変数やその状態を引き継ぐ。
  const {connectWallet,chainId,account,checkChainId,checkMetaMaskInstalled,contractWithSigner,isOwner,checkIfOwner, username,walletConnected,setWalletConnected} = useMetaMask();


  const handleConnectWallet = async () => {
      await connectWallet();
  }

  useEffect(() => {
    console.log(`You changed the page to: ${location.pathname}`) 
    if (account !== ''){
      console.log('アカウントそのままページ遷移')
    } else if (account === ''){///'account'が初期化された状態のとき
      checkMetaMaskInstalled()
      checkChainId()
      //setWalletConnected(false);
      console.log('初期レンダリングもしくは、account情報が空になってmainページへ遷移してきた。')
    }
  }, [location]); 

  ///アカウント情報やチェーン情報が変わったら最初に戻る
  useEffect(() => {
    if (!account || !chainId) {
      checkMetaMaskInstalled()
      checkChainId()
      console.log('account, chainIdが変更があり、')
    }
  }, [account, chainId]);


    // return(
    //     <Box bg="gray.100">
    //     <Flex direction="column" align="center" bg="gray.100" color="blue.900" minH="100vh" >
    //     {/* <Heading as="h2" size="3xl" fontWeight="bold"  color="grey:800">
    //     IDEA FLOW BANK
    //     </Heading> */}
    //     <Image src={IdeaFlowBankIconImage} 
    //     alt="IdeaFlowBankIcon" 
    //     w="323px"   // 幅を300pxに固定
    //     h="323px"   // 高さを200pxに固定
    //     //objectFit="cover"   // 上下をトリミング 
    //   />

    //     <Flex as="nav"
    //     align="center"
    //     justify="space-between"
    //     wrap="wrap"
    //     padding="1.5rem"
    //     color="grey:800"
    //     >
    //     {walletConnected&&<IdeaGroupsTree walletConnected={walletConnected} />}
    //     </Flex>
    //     </Flex>
    //   </Box>
    // );
    return (
      <Box bg="gray.100">
      <Flex direction="column" align="center" bg="gray.100" color="blue.900" minH="100vh" p={5}>
  
        <Image src={IdeaFlowBankIconImage} 
          alt="IdeaFlowBankIcon" 
          w="323px"   // 幅を300pxに固定
          h="323px"   // 高さを200pxに固定
          //objectFit="cover"   // 上下をトリミング 
        />
              {account === '' ? (
              <Button colorScheme="darkblue" variant="outline" onClick={handleConnectWallet}>
                  Connect to MetaMask
              </Button>
              ) : (
              chainId ? (
            
                  <Flex as="nav"
                  align="center"
                  justify="space-between"
                  wrap="wrap"
                  padding="1.5rem"
                  color="grey:800"
                  >
  {/*         {walletConnected&&<IdeaGroups walletConnected={walletConnected} />}  */}
           {walletConnected&&<IdeaGroupsTree walletConnected={walletConnected} />}
  
              </Flex>
          ) : (
            <Box textAlign="center" fontWeight="bold" fontSize="2xl" mb="20">
              Sepoliaに接続してください
            </Box>
          )
        )}
      </Flex>
      </Box>
      
    );
}

export default IdeaTreePage;