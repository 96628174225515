import IdeaCard from './IdeaCard';
import { useState,useEffect,useRef } from 'react';
import { Box, VStack,Text,Button, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { IoAddCircle } from "react-icons/io5";
import NewIdea from './NewIdea';
import { useMetaMask } from '../services/MetaMaskContext';
import WaitView from '../services/WaitView';
import { ProfitLossMap,BusinessModelMap,TmpIdea } from "../models"


export default function IdeaGroups({ walletConnected }) {

  const {key,allIdea,getAllIdea,viewStatus,setViewStatus,isFirstRender,
    setIsFirstRender,keyState,setKeyState,
    getMyPortfolio,fetchMembers,makeMemberAddressMapFromSC,allProfitLossMap,setAllProfitLossMap,
    allBusinessModelMap,setAllBusinessModelMap,
    fetchAllModels,allTmpIdea,setAllTmpIdea,myTmpIdea,setMyTmpIdea,filterTmpModelsByAccount,account,getMyTmpIdea} = useMetaMask();

  const [isOpen, setIsOpen] = useState(false);
  




  useEffect(() => {
    // 初回レンダリングの後にフラグをfalseに設定

    

    if (isFirstRender) {
      GetIdeasJobs();
    }
  }, []);

  const GetIdeasJobs = async() =>{
    setViewStatus(true)
    await setIsFirstRender(false);
    await getAllIdea();
    await getMyPortfolio();
    await fetchMembers();
    await makeMemberAddressMapFromSC();
    await fetchAllModels(BusinessModelMap,setAllBusinessModelMap);
    await fetchAllModels(ProfitLossMap,setAllProfitLossMap)
    await fetchAllModels(TmpIdea,setAllTmpIdea)
    //setMyTmpIdea(filterTmpModelsByAccount(allTmpIdea,account))
    await getMyTmpIdea()
    console.log('GetIdeasJobsPortfolios')
    setViewStatus(false)

    
  }



  useEffect(() => {
    // 初回レンダリングではない時のみgetAllIdeaを実行
    const keyUpdate = async () => {
      await GetIdeasJobs();
      
      console.log('key',key,'keyState',keyState)
      setKeyState(false)
    }
    if (walletConnected && !isFirstRender && keyState) {
      keyUpdate();
    }
    
  }, [key]);


  
  const onOpen = () => {
    setIsOpen(true);
  };
  
  const onClose = () => setIsOpen(false);



  return (
    <VStack spacing={5}>
      

        <Text>完全なる新規アイデアを追加するには下記をクリック</Text>
        <Button
          size="lg" // ボタンのサイズを大きくする
          colorScheme="teal"
          onClick={onOpen}
          borderRadius="full" // ボタンを円形にする
          _hover={{
            backgroundColor: "teal.400",
            boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.1)",
          }}
          style={{ width: "100px", height: "100px" }} // widthとheightを指定してボタンのサイズを調整する
        >
          <IoAddCircle size="3em"/>
        </Button>
         <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent maxWidth="800px">
            <ModalHeader
             textAlign="center">
                完全・新規アイデア
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            <NewIdea parentId={0} onClose={onClose}/>
            </ModalBody>
            <ModalFooter
                justifyContent="center"
            >
            </ModalFooter>
          </ModalContent>
        </Modal>

      
      {/* ここで、アイデアを引っ張ってくるまでのクルクルを追加 */}
      {viewStatus && (
        <WaitView viewStatus={viewStatus} />
      )} 


      {allIdea.reduce((groups, idea) => {
        const keyy = idea[1];
        if (!groups[keyy]) {
          groups[keyy] = [];
        }
        groups[keyy].push(idea);
        return groups;
      }, []).map((group, index) => {
        // idea[6]がtrueのものだけを取り出す
        const validIdeas = group.filter((idea) => idea[6]);

        // validIdeasが空配列でない場合のみヘッダーとIdeaCardをレンダリング
        return validIdeas.length > 0 ? (
   
             <VStack spacing={4} align="stretch">
          <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" bg="teal.300" color="white">
            <Heading as="h3" size="lg" textAlign="center">
              親のアイデア No. {index}
            </Heading>
          </Box>
          {validIdeas.map((idea, idx) => (
            <IdeaCard key={idx} idea={idea} />
          ))}
        
      );
      </VStack>
        ) : null;
      })}

    </VStack>
  );
}

