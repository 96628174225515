import React, { useState } from 'react';


import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Input,
  Flex,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  FormControl,
  FormLabel,
  Heading,
  HStack
} from '@chakra-ui/react';


///BusinessModelInput 親アイデアのデータを左側に併記する実装を行うべし 11/28


function BusinessModelInput({businessModelInput,setBusinessModelInput,parentBusinessModel}) {


  // Accordion index state
  const [accordionIndex, setAccordionIndex] = useState(null);


  // Handle accordion change
  const handleAccordionChange = index => {
    setAccordionIndex(accordionIndex === index ? null : index);
  };

  // Handle input change
  const handleChange = (item, number, value) => {
    if (value !== "") {
      setBusinessModelInput(prevState => ({
      ...prevState,
      [item]: {
        ...prevState[item],
        [number]: value
      }
      
    }));
  }
  };



  const items_dict_ja2en = {
    '顧客セグメント':'customerSegment',
    '価値提案':'valueProposition',
    'チャネル':'channel',
    '顧客との関係':'relationshipWithCustomers',
    '収益の流れ':'incomeFlow',
    '主なリソース':'resources',
    '主な活動':'activities',
    '主なパートナー':'partners',
    'コスト構造':'costStructure'
  };

  const items_dict_en2ja = {
    'customerSegment':'顧客セグメント',
    'valueProposition':'価値提案',
    'channel':'チャネル',
    'relationshipWithCustomers':'顧客との関係',
    'incomeFlow':'収益の流れ',
    'resources':'主なリソース',
    'activities':'主な活動',
    'partners':'主なパートナー',
    'costStructure':'コスト構造'
  };
      // Accordion items
  const items = [
        '顧客セグメント',
        '価値提案',
        'チャネル',
        '顧客との関係',
        '収益の流れ',
        '主なリソース',
        '主な活動',
        '主なパートナー',
        'コスト構造',
      ];
    

  const items_inEnglish = [
    'customerSegment',
    'valueProposition',
    'channel',
    'relationshipWithCustomers',
    'incomeFlow',
    'resources',
    'activities',
    'partners',
    'costStructure',
  ];
  


  const BusinessModelAccordion = ({ businessModelEntries}) => {
   
    const parsedBusinessModelEntries = items_inEnglish.reduce((acc, label) => {
      try {
        acc[label] = JSON.parse(businessModelEntries[label]);
      } catch (e) {
        //console.error(`JSON parsing error on ${label}: `, e);
        acc[label] = businessModelEntries[label]; // パースに失敗した場合は元の値を使用
      }
      return acc;
    }, {});
    

    
    return (
      <VStack align="stretch" spacing={4} m={4}>
        <Heading as="h2" size="lg">Business Model Map</Heading>
        <Accordion allowToggle index={accordionIndex} onChange={handleAccordionChange}>
          {items_inEnglish.map((label) => {
            return (
              <AccordionItem key={label}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {items_dict_en2ja[label]}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <VStack>
                    <HStack spacing={300}>
                      <FormLabel>Parent Model</FormLabel>
                      <FormLabel>New Input</FormLabel>
                    </HStack>
                    {[...Array(3)].map((_, i) => (
                      <HStack key={`hstack-${label}-${i}`} alignItems="center" spacing={4}>
                        <FormLabel>{i + 1}</FormLabel>
                        <Box height="2.5rem" width="27rem" display="flex" alignItem="center">
                          <Text
                              fontSize="14px"
                              whiteSpace="normal" // テキストを折り返す
                              textAlign="left" // テキストを左揃えにする
                              overflow="hidden" 
                              textOverflow="ellipsis"
                          >
                            {parsedBusinessModelEntries[label] && parsedBusinessModelEntries[label][i + 1] ? parsedBusinessModelEntries[label][i + 1] : '値無し'}
                          </Text>
                        </Box>
                        <Input
                          key={`input-${label}-${i + 1}`}
                          height="2.5rem"
                          width="15rem"
                          placeholder={`${items_dict_en2ja[label]} ${i + 1}`}
                          defaultValue={businessModelInput[label]?.[i + 1] || ''}
                          onBlur={(e) => handleChange(label, i + 1, e.target.value)}
                        />
                      </HStack>
                    ))}
                  </VStack>
                </AccordionPanel>
              </AccordionItem>
            );
          })}

        </Accordion>
      </VStack>


    );
  };

  
  return (
    <Flex
      direction="column"
      alignItems="center"
      h="100vh"
      mt="1vh"
      mb="10vh"
    >
      <Text alignItems="center" fontSize="20px">ビジネスモデルを記載ください</Text>

        <BusinessModelAccordion businessModelEntries={parentBusinessModel}/>

    </Flex>
  );
}

export default BusinessModelInput;

