import '../assets/App.css';
import React, { useState, useEffect } from "react";
import  {ethers}  from 'ethers'
import { Box, Link, Flex, Heading, Button, Input, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import { ChakraProvider } from '@chakra-ui/react';
import { useMetaMask } from '../services/MetaMaskContext';
import { Link as ChakraLink } from "@chakra-ui/react";
import { NavLink,useNavigate } from 'react-router-dom';
import { DataStore } from '@aws-amplify/datastore';
import { Members } from '../models';



function MemberPage () {
    const {account, chainId,contractWithSigner, walletConnected} = useMetaMask();
    const [members, setMembers] = useState(null); // membersを格納する状態を作成
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [addressToShow, setAddressToShow] = useState(null);
    


    // useEffect内で非同期処理を行います
    useEffect(() => {
        const fetchMembers = async () => {
            const members = await contractWithSigner.getMembers();
            console.log("members", members);

            // Fetch usernames for each member
            const membersWithUsernames = await Promise.all(members.map(async (address) => {
                const memberData = await DataStore.query(Members, c => c.address.eq(address.toLowerCase()));
                console.log("memberData", memberData);
            
                // memberData[0] が存在すればその username を、存在しなければ 'N/A' を設定します
                const username = memberData.length > 0 ? memberData[0].username : 'N/A';
                return { address, username };
            }));
            
            setMembers(membersWithUsernames); // 状態を更新
        };

        fetchMembers(); // fetchMembersを呼び出し
    }, [contractWithSigner]); // contractWithSignerが変わるたびに非同期処理を再実行します


    ///アカウント情報やチェーン情報が変わったら最初のページに遷移させる！
    useEffect(() => {
        if (!account || !chainId) {
            navigate('/');
        }
    }, [account, chainId, navigate]);

    const createUserDB = async (address, username) =>{
        try{
            await DataStore.save(
                new Members({
                "address": address,
                "username": username
            }))
            console.log('dynamoDB successfull')
            } catch (error){
            console.log(error)
            }
    }

    const handleMemberClick = (address) => {
        setAddressToShow(address); // クリックされたメンバーの address をセット
        setIsOpen(true); // モーダルを表示
    }    

    const onOpen = () => {
        setIsOpen(true);
    };

    const onClose = () => setIsOpen(false);

    
    return (
        <Box bg="gray.100">
            <Flex direction="column" align="left" bg="gray.100" color="blue.900" minH="100vh" p={5}>
                <Box>
                    Members: 
                    {members && members.map(member => (
                        // Text要素がクリックされたときに handleMemberClick 関数が実行されるように修正
                        <Box
                            onClick={() => handleMemberClick(member.address)}
                            border="1px" 
                            borderColor="gray.200" 
                            borderRadius="lg" 
                            padding="5" 
                            boxShadow="lg" 
                            marginBottom="15px"
                            marginTop="10px"
                            width="150px"
                            cursor="pointer" // Change the cursor to a pointer on hover
                            zIndex="1" // Boxを他の要素よりも前面に表示します
                            backgroundColor="gray.50" // 背景色を薄い灰色に設定します
                        >
                            {member.username || 'N/A'}
                        </Box>
                    ))}
                    
                </Box>

                <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxWidth="800px">
                <ModalHeader textAlign="center">
                    Details
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* ModalBody内でaddressToShowを表示するように修正 */}
                    Address: {addressToShow || 'No address selected'}
                </ModalBody>
                <ModalFooter justifyContent="center">
                </ModalFooter>
                </ModalContent>
                </Modal>
            </Flex>
        </Box>
    );
}

export default MemberPage;