import { HStack, VStack} from '@chakra-ui/react';
import EvaluateButton from './EvaluateButton';
import InvalidateIdeaButton from './InvalidateIdeaButton';
import IdeaBox from './IdeaBox'
import PortfolioByIdeaButton from './PortfolioByIdeaButton';
import { Tooltip ,Box,Flex} from "@chakra-ui/react";
import AddIdeaButton from './AddIdeaButton';
import ShowDistributionButton from './ShowDistributionButton';
import ShowScoreButton from './ShowScoreButton';
import ConfirmJobValueButton from './ConfirmJobValueButton';
import { useMetaMask } from '../services/MetaMaskContext';
import React, { useState, useCallback, useRef, useEffect } from 'react';

export default function IdeaCard({ idea }) {
    const [amIJobber,setAmIJobber] = useState(false)
    const [amIOwner,setAmIOwner] = useState(false)
    const [isConfirmed,setIsConfirmed] = useState(false)
  

    
    return (
      <HStack spacing={5}>
          <IdeaBox idea={idea}/>
        <VStack spacing={5}>
        <HStack>
        <AddIdeaButton idea={idea}/>
        </HStack>
        <HStack>
        {/* <EvaluateButton idea={idea} /> */}
        <ShowScoreButton idea={idea}/>
        </HStack>
        <HStack>
        {/* <PortfolioByIdeaButton ideaId={idea[0]} /> */}
        {/* <ShowDistributionButton ideaId={idea[0]}/> */}
        {/* <InvalidateIdeaButton ideaId = {idea[0]} /> */}
        </HStack>
        </VStack>
        
      </HStack>
    );
  }