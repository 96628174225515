import { Box, Button, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, VStack } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useMetaMask } from '../services/MetaMaskContext';
import { useState } from 'react';




function HamburgerMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {isOwner} = useMetaMask();

  return (
    <>
      <IconButton icon={<HamburgerIcon />} onClick={onOpen} />

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              {/* VStack コンポーネントを追加してボタンを配置 */}
              <VStack spacing={4} align="stretch">
                <Button as={RouterLink} to="/">Home</Button>
                {!isOwner&&<Button as={RouterLink} to="/mypage">My Page</Button>}
                {isOwner&&<Button as={RouterLink} to="/ownerpage">Owner Page</Button>}
                <Button as={RouterLink} to="/memberpage">Members</Button>
                <Button as={RouterLink} to="/ideatreepage">IDEA Trees</Button>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default HamburgerMenu;