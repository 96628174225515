// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { TmpIdea, BusinessModelMap, ProfitLossMap, ProfitAndLoss, BusinessModelCampus, MapAddressUsername, Members } = initSchema(schema);

export {
  TmpIdea,
  BusinessModelMap,
  ProfitLossMap,
  ProfitAndLoss,
  BusinessModelCampus,
  MapAddressUsername,
  Members
};