import React,{useEffect} from 'react';
import { CircularProgress, Box, Modal, ModalOverlay, ModalContent, Text } from '@chakra-ui/react';

const WaitTransaction = ({ transactionStatus}) => {


  return (
    <Modal isOpen={true} isCentered size="xs">
      <ModalOverlay />
      <ModalContent>
        <Box display='flex' justifyContent='center' p='6'>
          {transactionStatus === 'pending' && <CircularProgress isIndeterminate color="green.300" />}
          {transactionStatus === 'success' && <Text>トランザクションに成功しました！</Text>}
          {transactionStatus === 'error' && <Text>エラーが発生しました。。。m(_ _)m</Text>}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default WaitTransaction;


